import { combineReducers } from 'redux'; 
// import AuthReducer from './containers/auth-container/reducer/AuthReducer';
import AssignmentRequestReducer from './containers/assignment-request-container/reducer/AssignmentRequestReducer';
import StudentReducer from './containers/student-container/reducer/StudentReducer';


export default combineReducers({
    // AuthReducer,
    AssignmentRequestReducer,
    StudentReducer
});
