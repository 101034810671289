import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const RevenueDataTableComponent = ({ tableData }) => {


 
 

    const columns = [
        {
            name: 'Date',
            //   selector: 'transaction_date',
            cell: (row) => {
                const date = new Date(row?.transaction_date * 1000)
                const year = date.getFullYear();
                const month = date.getMonth() + 1; // Months are zero-based, so we add 1
                const day = date.getDate();
                const formattedDate = `${day}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${year}`;
                return (
                    <div style={{ fontSize: '18px' }}>{formattedDate}</div>
                )
            },
            sortable: true,
            minWidth: '200px',
            center: true
        },
        {
            name: 'Name',
            //   selector: 'user_name',
            cell: (row) => {
                return (
                    <div style={{ fontSize: '18px' }}>{row?.user_name}</div>
                )
            },
            sortable: true,
            minWidth: '200px',
            center: true
        },
        {
            name: 'Email',
            selector: 'user_email',
                        cell: (row) => {
                return (
                    <div style={{ fontSize: '18px' }}>{row?.user_email}</div>
                )
            },
            sortable: true,
            minWidth: '200px',
            center: true
        },
        {
            name: 'Country',
            selector: 'user_country',
                        cell: (row) => {
                return (
                    <div style={{ fontSize: '18px' }}>{row?.user_country}</div>
                )
            },
            sortable: true,
            minWidth: '200px',
            center: true
        },
        {
            name: 'Amount',
            selector: 'amount',
                        cell: (row) => {
                return (
                    <div style={{ fontSize: '18px' }}>{row?.amount/100}</div>
                )
            },
            sortable: true,
            minWidth: '200px',
            center: true
        }
    ];



    return (
        <React.Fragment>
            <div className="headFlex" style={{ width: '80%', margin: '2rem auto' }}>
             {
                tableData.length > 0 ? (
                    <DataTable
                    title=""
                    columns={columns}
                    data={tableData}
                    className="tableH-color tableb"
                    pagination
                    highlightOnHover
                    fixedHeader
                />
                ) : (
                    <p>No records to display</p>
                )
             }
            </div>
        </React.Fragment>
    );
};

export default RevenueDataTableComponent

