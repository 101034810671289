import React, { useEffect, useState } from 'react'
import { GROUP_STATUS_RESUME_REQUESTS } from '../assignment-request-container/AssignmentRequestConstants';
import ResumeExpertList from './ResumeExpertList';
import { getAllResumeRequests } from 'src/api/ResumeRequestApi';
import { SocketProvider } from 'src/socket-controller/SocketProvider';

const ResumeExpertContainer = () => {

const [ selectedStatus, setSelectedStatus ] = useState("Pending")
const [resumeRequestCount, setResumeRequestCount] = useState({
   'All Orders':0,
   'Progress Orders':0,
   'Delivered Orders':0,
})

useEffect(() => {
getResumeRequests()
}, [])

const getResumeRequests = async () => {
  const res = await getAllResumeRequests()
  setResumeRequestCount(res?.data?.counts)
}

    return (
        <React.Fragment>
         <SocketProvider id={Math.floor(10000000 + Math.random() * 90000000)}>
            <div className="tabsnrstl1">
                                 <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {
                                       GROUP_STATUS_RESUME_REQUESTS.map((status,index)=>{
                                          const tabNo=(index+1);
                                           return(
                                             <li className="nav-item" role="presentation" 
                                             onClick={()=>setSelectedStatus(status.value)} 
                                             key={index}>
                                             <div className={`nav-link ${selectedStatus === status.value?'active':''}`} id={`pills-tab${tabNo}`} data-bs-toggle="pill" data-bs-target={`#pills-${tabNo}`} type="button" role="tab" aria-controls={`pills-${tabNo}`} aria-selected="true">
                                                <div className="stretch-card asigntabnew1">
                                                   <div className="card-img-holder">
                                                      <div className="card-body">
                                                         <h4 className="font-weight-normal mb-3 d-flex align-items-center"><span className={`asigntabIcon icon${tabNo}`}>
                                                            <img src="/assets/images/assignment-overview/assignment-icon.svg" className="img-fluid img-icon" alt=""/> 
                                                            <i className="mdi mdi-plus"></i></span> <span className="valutext">
                                                            </span>
                                                         </h4>
                                                         <h6 className="card-text">Total</h6>
                                                         <h2 className="mt-0">{status.status}</h2>
                                                         <div className="pricdlrm1">{resumeRequestCount[status.status]}</div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </li>
                                           )
                                       })
                                    }
                                 </ul>
                              <div>
                                 <ResumeExpertList />
                                 {/* <p className='text-center mt-5'></p> */}
                              </div>
                              </div>
                              </SocketProvider>
        </React.Fragment>
    )
}

export default ResumeExpertContainer