export const GROUP_STATUS = [
    {
        status: 'Pending/Incomplete form',
        value:'Pending',
        count: 25,
        amount: 2454
    },
    {
        status: 'Awaiting payment',
        value:'Awaiting',
        count: 25,
        amount: 2454
    },
    {
        status: 'In progress',
        value:'In progress',
        count: 25,
        amount: 2454
    },
    {
        status: 'Cancelled',
        value:'Cancelled',
        count: 80,
        amount: 2356
    },
    {
        status: 'Delivered',
        value:'Delivered',
        count: 70,
        amount: 2656
    },
    {
        status: 'Rejected',
        value:'Rejected',
        count: 0,
        amount: 2543
    }

];

export const GROUP_STATUS_RESUME_REQUESTS = [
    {
        status: 'All Orders',
        value:'Pending',
        count: 25,
        amount: 2454
    },
    {
        status: 'Progress Orders',
        value:'In progress',
        count: 25,
        amount: 2454
    },
    {
        status: 'Delivered Orders',
        value:'Cancelled',
        count: 80,
        amount: 2356
    }

];

export default {
    GROUP_STATUS,
    GROUP_STATUS_RESUME_REQUESTS
};
