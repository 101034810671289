import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { getPaymentListFromStripe } from 'src/api/RevenueApi';
import RevenueDataTableComponent from 'src/components/welcome-page/RevenueDataTableComponent';
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
const Data = [
    {
      id: 1,
      year: 2016,
      userGain: 80000,
      userLost: 823
    },
    {
      id: 2,
      year: 2017,
      userGain: 45677,
      userLost: 345
    },
    {
      id: 3,
      year: 2018,
      userGain: 78888,
      userLost: 555
    },
    {
      id: 4,
      year: 2019,
      userGain: 90000,
      userLost: 4555
    },
    {
      id: 5,
      year: 2020,
      userGain: 4300,
      userLost: 234
    }
  ];

const RevenueContainer = () => {

    const [tableData, setTableData] = useState([])
    const [totalDeals, setTotalDeals] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [averageRevenue, setAverageRevenue] = useState(0)
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year), 
        datasets: [
          {
            label: "Users Gained ",
            data: [],
            backgroundColor: [
              "rgba(75,192,192,1)",
              "#50AF95",
              "#f3ba2f",
              "#2a71d0"
            ],
            borderColor: "black",
            borderWidth: 2
          }
        ]
      });

    useEffect(() => {
        getAllPayments()
    }, [])

    useEffect(() => {
      const processData = (data) => {
        // Step 1: Group data by date and calculate sum
        const groupedData = data.reduce((acc, item) => {
          const date = new Date(item.transaction_date * 1000).toLocaleDateString();
          acc[date] = (acc[date] || 0) + (item.amount/100);
          return acc;
        }, {});
  
        // Step 2: Generate a range of dates within the specified date range
        const startDate = new Date('2024-01-13');
        const endDate = new Date(/* Your end date here */);
        const dateRange = [];
        let currentDate = startDate;
  
        while (currentDate <= endDate) {
          dateRange.push(currentDate.toLocaleDateString());
          currentDate.setDate(currentDate.getDate() + 1);
        }
  
        // Step 3: Merge the calculated sums with the generated date range
        const mergedData = dateRange.map(date => ({
          date,
          amount: groupedData[date] || 0
        }));
  
      //   setGraphData(mergedData);
      setChartData({
        labels: mergedData.map((data) => data.date), 
        datasets: [
          {
            label: "Total Revenue",
            data: mergedData.map((data) => data.amount),
            backgroundColor: "rgb(255, 155, 82)",
            borderColor: "rgb(255, 155, 82)",
            borderWidth: 2
          }
        ]
      })
      };
  if(tableData.length){
      processData(tableData);
  }
    }, [tableData]);   

    const getAllPayments = async () => {
        let TotalDeal = 0
        let TotalRevenue = 0
        let AverageRevenue = 0
        const res = await getPaymentListFromStripe()
        if(res.data.data.length > 0){
          setTableData(res?.data?.data)
          let resData = res.data.data
          resData.forEach((item) => {
              TotalRevenue += item?.amount / 100
              AverageRevenue = (AverageRevenue + item?.amount) / resData.length
          })
          setTotalRevenue(TotalRevenue)
          setAverageRevenue(AverageRevenue.toFixed(0))
        }
    }

    return (
        <React.Fragment>
            <div className='revenue-stats-cards'>
                <Card style={{ width: 300, height:300 }}>
                    <h2>Number of Deals</h2>
                    <h3>{tableData.length}</h3>
                </Card>
                <Card style={{ width: 300, height:300 }}>
                    <h2>Average Deal Size</h2>
                    <h3>{averageRevenue} INR</h3>
                </Card>
                <Card style={{ width: 300, height:300 }}>
                    <h2>Total Revenue</h2>
                    <h3>{totalRevenue} INR</h3>
                </Card>
                {/* <div style={{width:'500px',height:'500px'}}>
                <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Users Gained between 2016-2020"
            },
            legend: {
              display: false
            }
          }
        }}
      />
      </div> */}
       <div style={{ width: '40%', position: 'relative', margin:'2rem auto',padding:'2rem' }}>
                                    <Bar data={chartData} options={{
                                        plugins: {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} 
                                    style={{marginTop:'2rem'}}
                                    />
                                    {/* <select 
                                        style={{ position: 'absolute',top:'0%', left:'70%', width: '200px', padding: '7px', border: 'none', background: '#fff', outline: 'none', boxShadow: '0px 0px 4px 4px #0000001a', borderRadius: '5px' }}
                                    >
                                        <option value="Daily">7 days</option>
                                        <option value="Last 14 days">2 weeks</option>
                                        <option value="Monthly">1 month</option>
                                        <option value="Yearly">1 year</option>
                                    </select> */}
                                </div>
                            </div>
            <RevenueDataTableComponent tableData={tableData} />
        </React.Fragment>
    )
}

export default RevenueContainer