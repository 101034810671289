/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';


export const supportSearchAPI = function (data) {
    const url = `${config.webUrl}contact/fetch-contact-us-query`;
    return postRequest({
        url,
        data,
    });
};

export const getStudentCounts = function(data){
    const url = `${config.webUrl}contact/get-student-count-details`;
    return postRequest({
        url,
        data
    })
}
