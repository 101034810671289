import { REQUEST_STATUS } from "src/constants/CommonConstants";
import { STUDENT_ACTIONS } from "../actions/StudentActions";

const initialState = () => ({
    currentRecord:{
        userId:'',
        userName:'',
        email:'',
        phone:'',
        deviceType:'',
        country:'',
        gender:'',
        registrationDate:'',
        isSubscrbed:false
    },
    search: {
        data: [],
        groupCounter:{'Registered':0,'Subscribed':0,'Non-Subscribed':0,'Cancelled Subscription':0,'Student reviews':0},
        currentPage: 0,
        pages: 0,
        total: 0,
        allTimeAppRegistered:0,
        allTimeWebRegistered:0,
        lastHoursRegisteredStudentsApp:0,
        lastHoursRegisteredStudentsWeb:0
    },
    searchReqStatus: null,
})

export default (state = initialState(), action) => {
    switch(action.type){
        //SEARCH
        case STUDENT_ACTIONS.STUDENT_SEARCH:{
            return {
                ...state,
                searchReqStatus:REQUEST_STATUS.PENDING
            }}
            case STUDENT_ACTIONS.STUDENT_SEARCH_SUCCESS: {
                return {
                    ...state,
                    
                    search: {
                        data:action.data,
                        groupCounter:action.groupCounter,
                        total:action.total,
                        allTimeAppRegistered:action.allTimeAppRegistered,
                        allTimeWebRegistered:action.allTimeWebRegistered,
                        lastHoursRegisteredStudentsApp:action.lastHoursRegisteredStudentsApp,
                        lastHoursRegisteredStudentsWeb:action.lastHoursRegisteredStudentsWeb
                    },
                    searchReqStatus: REQUEST_STATUS.SUCCESS,
                };
            }
            case STUDENT_ACTIONS.STUDENT_SEARCH_ERROR: {
                return {
                    ...state,
                    searchReqStatus: REQUEST_STATUS.ERROR,
                };
            }
            default:
            return state;
    }
}