// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
// import {
//   MenuFoldOutlined,
//   MenuUnfoldOutlined,
//   UploadOutlined,
//   UserOutlined,
//   VideoCameraOutlined,
// } from '@ant-design/icons';
// import { Layout, Menu, Button, theme } from 'antd';
// import { studentSearchAPI } from './api/StudentApi';
// import StudentContainer from './containers/student-container';
// import AssignmentContainer from './containers/assignment-request-container';
// import EFinderContainer from './containers/efinder-container/index';
// import UsersContainer from './containers/users-container/index';
// import LoginContainer from './components/Login';
// import WelcomeContainer from './components/welcome-page/WelcomeContainer';
// import Support from './components/welcome-page/Support'; 
// const { Header, Sider, Content } = Layout;
// const App = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer },
//   } = theme.useToken();


//   useEffect(() => {
//     getStudentsData()
//   }, [])

//   const getStudentsData = async () => {
//     const res = await studentSearchAPI()
//     console.log('res is', res)
//   }
//   return (
//     <Router>
//       <Layout>
//         <Sider trigger={null} collapsible collapsed={collapsed}
//         className='pt-4'
//         theme='light'
//           style={{
//             overflow: "auto",
//             height: "100vh",
//             position: "sticky",
//             top: 0,
//             left: 0
//           }}
//         >
//           <div className="demo-logo-vertical text-center mb-3">
//             <img src={collapsed === true ? '/assets/images/AddonLogoCollapse.png' : '/assets/images/AddonLogoExpand.png'} />
//           </div>
//           <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
//             <Menu.Item key="1">
//               <span>Dashboard</span>
//               <Link to="/" />
//             </Menu.Item>
//             <Menu.Item key="2">
//               <span>Student Overview</span>
//               <Link to="/students" />
//             </Menu.Item>
//             <Menu.Item key = "3">
//             <span>Users Overview</span>
//               <Link to="/users-overview" />
//             </Menu.Item>
//             <Menu.Item key="4">
//               <span>Assignment Requests</span>
//               <Link to="/assignment-requests" />
//             </Menu.Item>
//             <Menu.Item key="5">
//               <span>Resume Requests</span>
//               <Link to = "/resume-requests" />
//             </Menu.Item>
//             <Menu.Item key="6">
//               <span>E-Finder </span>
//               <Link to = "/email-finder" />
//             </Menu.Item> 
//             <Menu.Item key="7">
//               <span>Login </span>
//               <Link to = "/login" />
//             </Menu.Item>
//           </Menu>
//         </Sider>
//         <Layout>
//           <Header
//             style={{
//               padding: 0,
//               background: colorBgContainer,
//             }}
//           >
//             <Button
//               type="text"
//               icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
//               onClick={() => setCollapsed(!collapsed)}
//               style={{
//                 fontSize: '16px',
//                 width: 64,
//                 height: 64,
//               }}
//             />
//           </Header>
//           <Content
//           style={{ margin: "24px 16px 0", overflow: "initial" }}
//           >
//             <Routes>
//               <Route exact path='/' Component={() => <p>Home Page</p>} />
//               <Route path='/students' Component={StudentContainer} />
//               <Route path='/assignment-requests' Component={AssignmentContainer} />
//               <Route path='/resume-requests' Component={() => <p>Resume Requests Overview</p>}/>
//               <Route path='/email-finder' Component={EFinderContainer}/>
//               <Route path = '/users-overview' Component={UsersContainer} />
//               <Route path = '/login' Component={LoginContainer} />
//               <Route path = '/welcome' Component={WelcomeContainer} />
//               <Route path = '/support' Component={Support} />
//             </Routes>
//           </Content>
//         </Layout>
//       </Layout>

//     </Router>
//   );
// };
// export default App;

import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { studentSearchAPI } from './api/StudentApi';
import StudentContainer from './containers/student-container';
import AssignmentContainer from './containers/assignment-request-container';
import EFinderContainer from './containers/efinder-container/index';
import UsersContainer from './containers/users-container/index';
import LoginContainer from './components/Login';
import WelcomeContainer from './components/welcome-page/WelcomeContainer';
import Support from './components/welcome-page/Support';
import DashboardNavbar from './components/dashboard/DashboardNavbar';
import Sales from './components/welcome-page/Sales';
import RevenueContainer from './containers/revenue-container/index';
import OperationsContainer from './containers/operations-container/index';
import SearchUrl from './containers/efinder-container/SearchUrl';
import Graph from './containers/efinder-container/Graph';
import MailTemplates from './containers/efinder-container/MailTemplates';

const App = () => {
  const [isUserLoggedin, setIsUserLoggedIn] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken !== undefined && accessToken !== null) {
      setIsUserLoggedIn(true)
    } else {
      setIsUserLoggedIn(false)
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    getStudentsData()
  }, [])

  const getStudentsData = async () => {
    const res = await studentSearchAPI()
    console.log('res is', res)
  }
  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {isUserLoggedin === true && <DashboardNavbar />}
      <Routes>
        <>
          <Route exact path='/' Component={() => <p>Welcome</p>} />
          <Route path='/students' Component={StudentContainer} />
          <Route path='/assignment-requests' Component={AssignmentContainer} />
          <Route path='/resume-requests' Component={() => <p>Resume Requests Overview</p>} />
          <Route path='/email-finder' Component={EFinderContainer} />
          <Route path='/email-finder/search-url' Component={SearchUrl} />
          <Route path='/email-finder/mail-templates' Component={MailTemplates} />
          <Route path='/email-finder/graph' Component={Graph} />
          <Route path='/users-overview' Component={UsersContainer} />
          <Route path='/welcome' Component={WelcomeContainer} />
          {/* <Route path='/support' Component={Support} /> */}
          <Route path='/login' Component={LoginContainer} />
          {/* <Route path = '/sales' Component={Sales} /> */}
          <Route path = '/sales' Component={Support} />
          <Route path = '/revenue' Component={RevenueContainer} />
          <Route path = '/operations' Component={OperationsContainer}/>
        </>
      </Routes>
    </React.Fragment>
  );
};
export default App;