/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import 'lightpick/css/lightpick.css';
import Lightpick from 'lightpick'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
// import './DateTimePickerComponent.scss';

const DateTimePickerComponent = ({
    id,
    format,
    className,
    singleDate,
    isTimePeriodFilterActive,
    setIsTimePeriodFilterActive,
    value,
    onChange,    
    disabled,
    ...rest
}) => {
    let date = value;
    const pickerRef = useRef(null);

    if (typeof value === 'string' && value.length > 0) {
        date = new Date(value);
    }

    if (!date?.getTime || Number.isNaN(date.getTime())) {
        date = '';
    }   
    const [dateRange, setDateRange] = useState('');
    const [sDate, setSDate] = useState('')
    const [eDate, setEDate] = useState('')
    const handleDateRangeChange = (start, end) => {
      // setIsTimePeriodFilterActive(false)
      const formattedStartDate = moment(start).format("YYYY-MM-DD");
      const formattedEndDate = moment(end).format("YYYY-MM-DD");
      const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
      if(formattedStartDate!=='Invalid date' && formattedEndDate !== 'Invalid date')
      {
        setDateRange(newDateRange);
       
        onChange(newDateRange);
      }
      
    };

    const handleSelect = (start,end) => {
      setSDate(start)
      setEDate(end)
      // setIsTimePeriodFilterActive(false)
      let formattedStartDate = moment(start).format(format);
      let formattedEndDate = moment(end).format(format);
      if(formattedStartDate!=='Invalid date' && formattedEndDate !== 'Invalid date')
      {
      
      const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setDateRange(newDateRange);
        // onChange(newDateRange);
      } else if(formattedStartDate !== "Invalid date"){
         formattedStartDate = moment(start).format(format);
       formattedEndDate = moment().format(format);
      const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setDateRange(newDateRange);
      }
    }
  
    const handlePickerClose = () => {
      const picker = pickerRef.current;
      if (picker) {
        const startDate = picker.getStartDate();
        const endDate = picker.getEndDate();
        handleDateRangeChange(startDate, endDate);
      }
    };
    useEffect(() => {
        const picker = new Lightpick({
          field: document.getElementById(id),
          singleDate,
          numberOfMonths:(singleDate)?1:2,
          footer: false,
          maxDate: moment(),
          onSelect: (start, end) => {
           handleSelect(start,end)
          },
          // onClose: () => handlePickerClose(),
        });
        pickerRef.current = picker;
      }, []);
      useEffect(() => {
        if(isTimePeriodFilterActive){
          setDateRange('')
        }
      },[isTimePeriodFilterActive])

    return (
            <div style={{display:'flex'}}>
                <input type="text"
                    id={id}
                    className={classNames(className, 'date-time-picker-component')}
                    value={dateRange}
                    {...rest}
                    readOnly
                /> 
                <div className='d-flex dailyTxt1' style={{background:'none',border:'none'}}>
                  <button className='btndaten1 active me-1 ms-1' onClick={() =>
                  {
                    onChange({timePeriod:"All"})
                    setDateRange('')
                  }
                    }
                    disabled={dateRange === ''}
                    >Reset</button>
                  <button className='btndaten1 active' onClick={() => 
                    {
                      setIsTimePeriodFilterActive(!isTimePeriodFilterActive)
                      handleDateRangeChange(sDate,eDate)
                    }
                    }
                    disabled={dateRange === ''}
                    >Apply</button>
                  </div>  
                {/* <button onClick={() => handleDateRangeChange(sDate,eDate)}>Apply</button>                   */}
            </div>
        // <DateTimePicker
        //     className={classNames(className, 'date-time-picker-component')}
        //     disableClock
        //     value={date}
        //     // clearIcon={false}
        //     onChange={onChange}
        //     format={format}
        //     disabled={disabled}
        // />
    );
};

DateTimePickerComponent.propTypes = {
    // onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    format: PropTypes.string,
};

DateTimePickerComponent.defaultProps = {
    className: '',
    value: '',
    disabled: false,
    format: 'y-MM-dd',
};

export default DateTimePickerComponent;