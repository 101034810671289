export const STUDENT_ACTIONS = {
    STUDENT_SEARCH:'STUDENT_SEARCH',
    STUDENT_SEARCH_SUCCESS: 'STUDENT_SEARCH_SUCCESS',
    STUDENT_SEARCH_ERROR: 'STUDENT_SEARCH_ERROR',
}

export const studentSearchAction = (payload) => ({
    type:STUDENT_ACTIONS.STUDENT_SEARCH,
    ...payload
})

export default {
    STUDENT_ACTIONS,
    studentSearchAction
}