// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import MainContainer from 'src/containers/main-container/MainContainer';
// import { appInitAction } from 'src/containers/app-container/actions/AppActions';
// import LoginForm from './containers/auth-container/login/LoginForm';
// import PrivateRoute from './hoc/PrivateRouteHOC';
// import { QueryClient, QueryClientProvider } from "react-query";
// import AlertContainer from './containers/alert-container/HeaderContainer';
// import { SocketProvider } from './contexts/SocketProvider';

// export default () => {
//     const dispatch = useDispatch();
//     const { userId } = useSelector((state) => state.AuthReducer)
//     dispatch(appInitAction());
//     const queryClient = new QueryClient();

//     return (
//         <QueryClientProvider client={queryClient}>
//         <BrowserRouter>
//             <>             
//             <AlertContainer />   
//             <SocketProvider id={userId}>
//                 <Switch>
//                     <Route
//                         exact
//                         path="/login"
//                         component={LoginForm}
//                     /> 
//                      <PrivateRoute
//                         path="/"
//                         component={MainContainer}
//                     /> 
//                     {/* <Route path="/" element={<PrivateRoute component={MainContainer} />} /> */}
//                 </Switch>
//                 </SocketProvider>
//             </>
//         </BrowserRouter>
//         </QueryClientProvider>
//     );
// };
