/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const loginAPI = ({
    email,
    password,
}) => {
    const url = `${config.baseUrl}admin/login`;
    return postRequest({
        url,
        data: {
            email,
            password,
        },
    });
};