/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';


export const  getAllFinderUsers = function (data) {
    const url = `${config.baseUrl}finder/get-users`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers,
    });
};

export const  SearchUrlList = function (data) {
    const url = `${config.baseUrl}finder/linkedin-search-url-list`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    });
};

export const editReferenceName = function (data) {
    const url = `${config.baseUrl}finder/update-url-reference-name`;
    // const url = `http://localhost:8000/api/v1/finder/update-url-reference-name`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}

export const updateMailTemplateId = function (data) {
    const url = `${config.baseUrl}finder/update-mail-templateId`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}
// email-csv
export const emailcsv = function (data) {
    const url = `${config.baseUrl}finder/email-csv`;
    // const url = `http://localhost:8000/api/v1/finder/email-csv`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}

// download email-csv
export const downloadEmail = function (data) {
    const url = `${config.baseUrl}finder/download-email`;
    // const url = `http://localhost:8000/api/v1/finder/email-csv`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}

export const saveNewTemplate = function (data) {
    const url = `${config.baseUrl}finder/save-mail-template`;
    // const url = `http://localhost:8000/api/v1/finder/save-mail-template`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}

export const saveURL = function (data) {
    const url = `${config.baseUrl}finder/save-url`;
    
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}


export const editRow = function (data) {
    const url = `${config.baseUrl}finder/editRow`;
    
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}

export const totalData = function (data) {
    const url = `${config.baseUrl}finder/total-users`;
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}

export const getMailTemplates = function (data) {
    const url = `${config.baseUrl}finder/mail-templates`;
    
    const headers = {
        "Content-Type": "Application/json",
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    return postRequest({
        url,
        data,
        headers
    })
}
