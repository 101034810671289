import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd';
import DataTable from 'react-data-table-component';
import { saveProspectDetails } from 'src/api/ProspectingDetailApi';
import { countries } from 'country-list-json'

const DataTableComponent = ({ tableData, manageColumnsArr, isManageColumnApplied }) => {

  const [prospectingDate, setProspectingDate] = useState('')
  const [prospectingTime, setProspectingTime] = useState('')
  const [prospectingType, setProspectingType] = useState('')
  const [prospectingFrequency, setProspectingFrequency] = useState('')
  const [prospectingDisposition, setProspectingDisposition] = useState('')
  const [feedback, setFeedback] = useState('')
  const [leadStage, setLeadStage] = useState('')
  const [prospectingTableData, setProspectingTableData] = useState([])
  const [isSubmitting, setIsSubmitting] = useState('')
  const [isSuccessModalOpen, setIsSuccessModal] = useState(false)
  const [csvExportArray, setCsvExportArray] = useState([])

  useEffect(() => {
    setProspectingTableData(tableData)
  }, [tableData])


  const saveProspectingDetails = async (rowData) => {
    // console.log('res is', res)
    if(!rowData?.prospectingDetails?.[0]?.prospectingDate || !rowData?.prospectingDetails?.[0]?.prospectingTime || !rowData?.prospectingDetails?.[0]?.prospectingType || !rowData?.prospectingDetails[0]?.prospectingFrequency || !rowData?.prospectingDetails?.[0]?.leadStage){
      setIsSubmitting(rowData._id)
    }else {
    const res = await saveProspectDetails({ ...rowData.prospectingDetails[0], user_id: rowData._id, prospect_type:rowData.query_type })
    console.log('rowData', rowData.prospectingDetails)
    if(res !== undefined){
      setIsSuccessModal(true)
    }
    }
  }

  const handleRowDataChange = (rowData, eventName, eventValue) => {
    console.log('handle row change', eventName, eventValue)
    const rowIdx = prospectingTableData.findIndex(row => row._id === rowData._id)
    // setProspectingTableData((prev) => {
    //   const arr = [...prev]
    //   arr[rowIdx]['prospectingDetails'][0][eventName] = eventValue
    //   return arr
    // })
    setProspectingTableData((prev) => {
      const arr = [...prev];

      if (arr[rowIdx] && Array.isArray(arr[rowIdx]['prospectingDetails'])) {
        // Check if prospectingDetails array exists at the specified rowIdx
        if (!arr[rowIdx]['prospectingDetails'][0]) {
          // If not, initialize it with an empty object
          arr[rowIdx]['prospectingDetails'][0] = {};
        }

        // Set the value for the specified eventName
        arr[rowIdx]['prospectingDetails'][0][eventName] = eventValue;
      } else {
        // If prospectingDetails or its inner objects don't exist, handle accordingly
        console.error("prospectingDetails or its inner objects do not exist at rowIdx", rowIdx);
        // You might want to throw an error, log a message, or handle it in some other way
      }
      return arr;
    });
  }


  const columns = [
    {
      name: 'Date',
      // selector: 'created_at',
      cell: (row) => {
        const originalDateString = row.created_at;
        const originalDate = new Date(originalDateString);

        const day = originalDate.getUTCDate().toString().padStart(2, '0');
        const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');  // Months are 0-based
        const year = originalDate.getUTCFullYear();

        const convertedDateString = `${day}-${month}-${year}`;
        return (
          <>{convertedDateString}</>
        )
      },
      sortable: true,
      maxWidth: '150px',
      center: true,
      omit: manageColumnsArr.includes('date') === false && isManageColumnApplied === true
    },
    {
      name: 'Time',
      // selector: 'created_at',
      cell: (row) => {
        const originalDateString = row.created_at;
        const originalDate = new Date(originalDateString);

        const convertedDateString = originalDate.toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });

        return (
          <>{convertedDateString}</>
        )
      },
      sortable: true,
      maxWidth: '150px',
      center: true,
      omit: manageColumnsArr.includes('time') === false && isManageColumnApplied === true
    },
    {
      name: 'Query Type',
      selector: 'query_type',
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('query_type') === false && isManageColumnApplied === true
    },
    {
      name: 'Module',
      // selector: 'pageType',
      cell: (row) => (
        <>{row?.pageType === undefined ? `Sign up` : `${row.pageType}`}</>
      ),
      sortable: true,
      maxWidth: '150px',
      center: true,
      omit: manageColumnsArr.includes('module') === false && isManageColumnApplied === true
    },
    {
      name: 'Plan Type',
      // selector: 'pageType',
      cell: (row) => (
        <>{row?.banner_selected
          === undefined ? `--` : `${row.banner_selected
          }`}</>
      ),
      sortable: true,
      maxWidth: '150px',
      center: true,
      // omit: manageColumnsArr.includes('module') === false && isManageColumnApplied === true
    },
    {
      name: 'First Name',
      selector: 'F_Name',
      sortable: true,
      maxWidth: '180px',
      center: true,
      omit: manageColumnsArr.includes('first_name') === false && isManageColumnApplied === true
    },
    {
      name: 'Last Name',
      selector: 'L_Name',
      sortable: true,
      maxWidth: '180px',
      center: true,
      omit: manageColumnsArr.includes('last_name') === false && isManageColumnApplied === true
    },
    {
      name: 'Phone  ',
      // selector: 'calling_code',
      cell: (row) => (
        <>{row?.query_type === 'signup' ? `+${row.calling_code}${row.Contact}` : `${row.Contact}`}</>
      ),
      sortable: true,
      maxWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('phone') === false && isManageColumnApplied === true
    },
    {
      name: 'Country',
      cell: (row) => {
        const country = countries.find(c => c.dial_code === `+${row.calling_code}`);
        return (
          <>{country?.name}</>
        )
      },
      sortable: true,
      maxWidth: '150px',
      center: true,
      omit: manageColumnsArr.includes('country') === false && isManageColumnApplied === true
    },
    {
      name: 'Email ID ',
      selector: 'Email',
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('email') === false && isManageColumnApplied === true
    },
    {
      name: ' Notes   ',
      selector: 'query_msg',
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('notes') === false && isManageColumnApplied === true
    },
    {
      name: 'View',
      cell: (row) => (
        <button className="viewBtn" style={{ margin: '0rem auto' }} onClick={(e) => handleViewClick(e, row)}><img src="/assets/images/view.svg" /> </button>
      ),
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('view') === false && isManageColumnApplied === true
    },
    {
      name: 'Prospecting Date',
      selector: 'phone',
      cell: (row) => (
        <input type='date'
          value={row?.prospectingDetails[0]?.prospectingDate?.split("T")[0] || ''}
          name="prospectingDate"
          className={isSubmitting === row?._id && !row?.prospectingDetails?.[0]?.prospectingDate ? 'error-input' : ''}
          onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)}
        />
      ),
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('prospecing_date') === false && isManageColumnApplied === true
    },
    {
      name: 'Prospecting time',
      // selector:'phone',
      cell: (row) => (
        <input type='time'
          value={row?.prospectingDetails[0]?.prospectingTime || ''}
          name='prospectingTime'
          className={isSubmitting === row?._id && !row?.prospectingDetails?.[0]?.prospectingTime ? 'error-input' : ''}
          onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)}
        />
      ),
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('prospecting_time') === false && isManageColumnApplied === true
    },
    {
      name: 'Prospecting type',
      // selector:'phone',
      cell: (row) => (
        <select value={row?.prospectingDetails[0]?.prospectingType || ''} onChange={(e) => handleRowDataChange(row, "prospectingType", e.target.value)}
        className={isSubmitting === row?._id && !row?.prospectingDetails?.[0]?.prospectingType ? 'error-input' : ''}
        >
          <option value="" selected disabled hidden>Select an option</option>
          <option>Call</option>
          <option>WhatsApp call</option>
          <option>Email</option>
        </select>
      ),
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('prospecting_type') === false && isManageColumnApplied === true
    },
    {
      name: 'Prospecting frequency',
      // selector:'phone',
      cell: (row) => (
        <select value={row?.prospectingDetails[0]?.prospectingFrequency} onChange={(e) => handleRowDataChange(row, "prospectingFrequency", e.target.value)}
        className={isSubmitting === row?._id && !row?.prospectingDetails[0]?.prospectingFrequency ? 'error-input' : ''}
        >
          <option value="" selected disabled hidden>Select an option</option>
          <option>First</option>
          <option>Second</option>
          <option>Third</option>
        </select>
      ),
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('prospecting_frequency') === false && isManageColumnApplied === true
    },
    {
      name: 'Lead Stage',
      // selector:'phone',
      cell: (row) => (
        <select value={row?.prospectingDetails[0]?.leadStage}
        className={isSubmitting === row?._id && !row?.prospectingDetails?.[0]?.leadStage ? 'error-input' : ''}
          name="leadStage"
          onChange={(e) => handleRowDataChange(row, "leadStage", e.target.value)}>
          <option value="" selected disabled hidden>Select an option</option>
          <option>Yet To Dial</option>
          <option>Did Not Pick</option>
          <option>Invalid Number</option>
          <option>Not Connected</option>
          <option>Disconnected</option>
          <option>Call Busy</option>
          <option>Service Purchased</option>
          <option>Referral Provided</option>
          <option>Call Rescheduled</option>
          <option>Casually Browsing</option> 
          <option>Technical Issue</option>
          <option>Not Interested</option>

        </select>
      ),
      sortable: true,
      center: true,
      minWidth: '200px',
      omit: manageColumnsArr.includes('lead_stage') === false && isManageColumnApplied === true
    },
    // {
    //   name: 'Prospecting disposition',
    //   // selector:'phone',
    //   cell: (row) => (
    //     <select value={row?.prospectingDetails[0]?.prospectingDisposition} onChange={(e) => handleRowDataChange(row, "prospectingDisposition", e.target.value)}
    //     className={isSubmitting === row?._id && !row?.prospectingDetails?.[0]?.prospectingDisposition ? 'error-input' : ''}
    //     >
    //       <option value="" selected disabled hidden>Select an option</option>
    //       <option>Yet to Dial</option>
    //       <option>Do Not Pick</option>
    //       <option>Invalid Number</option>
    //       <option>Technical Issue</option>
    //       <option>Disconnected</option>
    //       <option>Call Busy</option>
    //       <option>Connected</option>
    //     </select>
    //   ),
    //   sortable: true,
    //   minWidth: '200px',
    //   center: true
    // },
    {
      name: 'Feedback',
      // selector:'phone',
      cell: (row) => (
        <textarea type='text' name="feedback" placeholder='Click here to write...' value={row?.prospectingDetails[0]?.feedback} onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)} />
      ),
      sortable: true,
      minWidth: '200px',
      center: true,
      omit: manageColumnsArr.includes('feedback') === false && isManageColumnApplied === true
    },
    {
      name: 'Status',
      // selector:'phone',
      cell: (row) => (
        <button className='FilterBtn' onClick={() => saveProspectingDetails(row)}>Save</button>
      ),
      sortable: true,
      minWidth: '200px',
      center: true
    }
  ];

  const handleViewClick = (e, row) => {
    e.preventDefault()
    setIsViewRow(row)
    openModal()
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewRow, setIsViewRow] = useState(null)

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function convertArrayOfObjectsToCSV(array) {
    let result;
  
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    // const keys = Object.keys([{
    //   'Date',

    // }]);
    const keys = ['Date', 'Time', 'Query Type', 'Module', 'First Name', 'Last Name', 'Phone', 'Country', 'Email Id', 'Notes', 'Prospecting Date', 'Prospecting time', 'Prospecting type', 'Prospecting frequency', 'Lead Stage', 'Feedback']
  
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        if(key === 'Date'){
          result+= item['created_at']
        }else if(key === 'Time'){
          result+= item['created_at']
        }else if(key === 'Query Type'){
          result+= item['query_type']
        }else if(key === 'Module'){
          result+= item['page_type']
        }else if(key === 'First Name'){
          result+= item['F_Name']
        }else if(key === 'Last Name'){
          result+= item['L_Name']
        }else if(key === 'Phone'){
          result+= item['Contact']
        }else if(key === 'Country'){
          const country = countries.find(c => c.dial_code === `+${item?.calling_code}`);
          result+= item[country.name]
        }else if(key === 'Email Id'){
          result+= item['Email']
        }else if(key === 'Prospecting Date'){
          result+= item?.['prospectingDetails']?.[0]?.['created_at']
        }else if(key === 'Prospecting time'){
          result+= item?.['prospectingDetails']?.[0]?.['created_at']
        }else if(key === 'Prospecting type'){
          result+= item?.['prospectingDetails']?.[0]?.['prospectingType']
        }else if(key === 'Prospecting frequency'){
          result+= item?.['prospectingDetails']?.[0]?.['prospectingFrequency']
        }else if(key === 'Lead Stage'){
          result+= item?.['prospectingDetails']?.[0]?.['leadStage']
        }else if(key === 'Feedback'){
          result+= item?.['prospectingDetails']?.[0]?.['feedback']
        }
        
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  

  function downloadCSV(array) {
    console.log('array is', array)
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
  
    const filename = 'export.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  const Export = ({ onExport }) => <button class="FilterBtn" onClick={e => onExport(e.target.value)}>Export</button>;
  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(csvExportArray)} />, [csvExportArray]);
  const handleRowSelection = (state) => {
  setCsvExportArray(state.selectedRows)
  }
  return (
    <React.Fragment>
      
      <div className="headFlex">
        <DataTable
          title=""
          columns={columns}
          data={prospectingTableData}
          actions={actionsMemo}
          className="tableH-color tableb"
          pagination
          highlightOnHover
          fixedHeader
          selectableRows
          onSelectedRowsChange={handleRowSelection}
        />
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2>View Details</h2>
        <ul className="tbVlist">
          <li><span>Name :</span> {viewRow?.F_Name} {viewRow?.L_Name} </li>
          <li><span> Date  :</span>  {viewRow?.created_at} </li>
          <li> <span>Module :</span> {viewRow?.query_type}</li>
          <li> <span>Phone:</span>  {viewRow?.Contact} </li>
          <li> <span>Email Id :</span>  {viewRow?.Email} </li>
          <li> <span>Notes :</span>   {viewRow?.query_msg}</li>
        </ul>
      </Modal>
      <Modal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModal(false)}>
      <h2 style={{textAlign:'center'}}>Your entry has been successfully updated.</h2>
      </Modal>
    </React.Fragment>
  );
};

export default DataTableComponent

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content modal-lg viewdtal p30">
        <span className="close-btn" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};
