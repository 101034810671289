/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';


export const studentSearchAPI = function (data) {
    const url = `${config.baseUrl}student/search-students`;
    return postRequest({
        url,
        data,
    });
};

export const getStudentsCount = function(data){
    const url = `${config.baseUrl}student/get-students/all`;
    return postRequest({
        url,
        data,
    });
}