import { formatDateDiff } from "src/libs/common"
import { Avatar, Badge } from 'antd';
import { useEffect, useState } from "react";
import { SocketProvider, useSocket } from "src/socket-controller/SocketProvider";
import {saveAssignmentChatMsgs, getAllAssignmentChats} from "src/api/AssignmentApi";

const AssignmentList = ({ assignments, setSelectedAssignment, selectedStatus, setBidTutors, setAdditionalService }) => {
// const socket = useSocket('http://localhost:8000')
const socket = useSocket('https://admin.addonstudy.com')
const [messagesArr, setMessagesArr] = useState([])
const [inputMessageValue, setInputMessageValue] = useState('')

useEffect(() => {
    if (socket) {
        // socket.on('messageIncoming', (data) => {
        //     // console.log('student message is',data)
        //     setMessagesArr((prev) => {
        //         if (prev !== undefined) {
        //             return [...prev, data]
        //         } else {
        //             return [data]
        //         }
        //     }
        //     )
        // });
        if (socket) {
            socket.emit('assignment_subscribe', {
                id: '743ehfjhebfuhe7hj',
                role: 'support',
                room_id:"655b1e379299052326dc7e47-6457"
            })
        }
    }
}, [socket])

useEffect(() => {
    if(socket){
    socket.on('studentMessage', async(data) => {
        const response = await getAllAssignmentChats({room_id:`655b1e379299052326dc7e47-6457`})
        setMessagesArr(response?.data?.data?.conversations)
        console.log('incoming msg is',response)
    });
}
}, [socket])

const handleMessageModalClick = (chatArray) => {
    console.log('chatArray', chatArray)
setMessagesArr(chatArray)
socket.emit('assignment_subscribe', {
    id: '743ehfjhebfuhe7hj',
    role: 'support',
    room_id:"655b1e379299052326dc7e47-6457"
})
}

const handleMessageSubmit = async () => {
    await socket.emit('messageResponseExpert', {
        text: inputMessageValue.trim(),
        attachment: '',
        // userId: userId,
        room_id: `655b1e379299052326dc7e47-6457`
    })
    await saveAssignmentChatMsgs({
        // student_id: studentId,
        // expert_id: userId,
        type: 'Expert',
        conversations: [{
            text: inputMessageValue.trim(),
            // userId: userId,
            // attachment: '',
            userRole: 'support',
            isSeen: false,
            createdAt: new Date()
        }],
        room_id: `655b1e379299052326dc7e47-6457`
    }).then(() => {
setInputMessageValue('')
setMessagesArr((prev) => {
    if (prev !== undefined) {
        return [...prev, {

            text: inputMessageValue.trim(),
            attachment: '',
            userRole: 'support',
            isSeen: false,
            createdAt: new Date()
        }]
    } else {
        return [{
            text: inputMessageValue.trim(),
            // userId: userId,
            room_id: `655b1e379299052326dc7e47-6457`,
            attachment: '',
            userRole: 'support',
            isSeen: false,
            createdAt: new Date()
            ,
            room_id: `655b1e379299052326dc7e47-6457`
        }]
    }
})
    })
}

    return (
        <>
        {/* <SocketProvider id={'34jwbfjh'}> */}
            <div className="table-responsive">
                <table className="table table-center datatablefnts datatable">
                    <thead>
                        <tr>
                            <th>S. No   </th>
                            {/* <th>Assmt. ID </th> */}
                            <th>Order ID</th>
                            <th>Student Name</th>
                            <th>Student Email Id </th>
                            <th>Student Contact N.</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Contact</th>
                            <th>Subject</th>
                            <th>Sub Subject</th>
                            <th>Number of Page </th>
                            <th>Words Count </th>
                            <th>Frontend Expert </th>
                            <th>Deadline </th>
                            <th>Deadline Left</th>
                            <th>Amount</th>
                            <th>Chat</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            assignments.map((assignment, index) => {
                                const remaningTime = formatDateDiff(assignment.deadline_date)
                                const paymentStatus = (assignment.payment_status == "paid-full") ? "paid" : "Un Paid"
                                return (
                                    <tr key={index}>
                                        <td><span className="sNo">{index + 1}</span> </td>
                                        <td>{assignment?.order_id}    </td>
                                        <td>{assignment?.user_id?.FirstName}   </td>
                                        {/* <td> */}
                                        {/* <div className="nambtntx"><a href="#">{assignment.user_id?.Name} </a></div> */}
                                        {/* </td> */}
                                        <td>
                                            {assignment?.user_id?.Email}
                                            <div className="messagebn1">
                                                <a href={`mailto:${assignment.basic_info?.Email}`} target="_blank">
                                                    <button><i className="mdi mdi-email-outline"></i> Message</button>
                                                </a>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">{assignment?.user_id?.Phone}</div>
                                            <div className="callphnwhaaap">
                                                <a href={`https://api.whatsapp.com/send?phone=${assignment.user_id?.calling_code}`} title="Share on whatsapp" target="_blank">
                                                    <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button> </a>
                                                <a href={`https://api.whatsapp.com/send?phone=${assignment.user_id?.calling_code}`} title="Share on whatsapp" target="_blank">
                                                    <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button>
                                                </a>
                                            </div>
                                        </td>
                                        <td>{assignment?.basic_info?.FirstName}</td>
                                        <td>{assignment?.basic_info?.Email}</td>
                                        <td>{assignment?.basic_info?.Phone}</td>
                                        <td>{assignment.subject}</td>
                                        <td>{assignment.sub_subject}   </td>
                                        <td>
                                            <div className="text-bold">{assignment.pages} </div>
                                        </td>
                                        <td>
                                            <div className="text-bold">{assignment.words !== undefined && assignment.words !== null ? assignment.words + ' words' : '--'} </div>
                                        </td>
                                        <td>
                                            <div className="nambtntx">{assignment.expert_id?.expert_name}</div>
                                        </td>
                                        <td>
                                            <div className="text-bold mb-2">{assignment.deadline_date.split('T')[0]}</div>
                                        </td>
                                        <td>
                                            <div className="text-bold">{remaningTime} </div>
                                        </td>
                                        <td>
                                            <div className="text-bold">{assignment.amount !== undefined && assignment.amount !== null ? '$ ' + assignment.amount : '--'}</div>
                                        </td>
                                        {/* <td><i className="mdi mdi-bell" style={{fontSize:'23px'}}></i></td> */}
                                        <td 
                                        style={{cursor:'pointer'}}
                                        data-bs-toggle="modal" data-bs-target="#assignmentChatboxModal" data-bs-whatever="@mdo" onClick={()  => handleMessageModalClick(assignment?.supportchats?.[0]?.conversations)}>
                                             <Badge count={0} offset={[-5, 10]}>
                                            {/* <Avatar shape="square" size="large" /> */}
                                            <i className="mdi mdi-bell" style={{ fontSize: '40px' }}></i>
                                        </Badge>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
            <div class="modal fade chatboxModal" id="assignmentChatboxModal" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content modal-content bg-white chat_box">
                        <div class="modal-header head">

                            <div class="user">
                                <div class="avatar">
                                    <img src="https://picsum.photos/g/40/40" />
                                </div>
                                <div class="name">Hi there! You're talking to AddonStudy &#58; &#41;</div>
                            </div>
                            <button type="button" class="close font-26 text-white" data-bs-dismiss="modal" aria-label="Close"> <i className="mdi mdi-close"></i></button>

                        </div>
                        <div class="modal-body body">
                            {/* <div className="dysMonth">Today</div> */}
                            {
                                messagesArr?.map((item) => {
                                    return (
                                        <div class={item.userRole === 'student' ? 'incoming' : 'outgoing'}>
                                        <div class={`bubble ${item.userRole === 'support'&& 'lower'}`}>
                                            <p>{item.text}</p>
                                            <div className="msgtime" style={{marginLeft:'4rem'}}>10:25PM</div>
                                        </div>
                                        </div>
                                    )
                                })
                            }
                            {/* <div class="incoming">
                                <div class="bubble">
                                    <p>Hi there  What would you like us to assist you with?</p>
                                    <div className="msgtime">10:25PM</div>
                                </div>
                                <div class="bubble">
                                    <p>Hi there  What would you like us to assist you with?</p>
                                    <div className="msgtime">10:26PM</div>
                                </div>
                            </div>
                            <div class="outgoing">
                                <div class="bubble lower">
                                    <p>Hi there  What would you like us to assist you with?</p>
                                    <div className="msgtime">10:27PM</div>
                                </div>
                                <div class="bubble">
                                    <p>Well you should get your Dad a cologne. Here smell it. Oh wait! ...</p>
                                    <div className="msgtime">10:28PM</div>
                                </div>
                            </div> */}
                            {/* <div class="typing">
                                <div class="bubble">
                                    <div class="ellipsis dot_1"></div>
                                    <div class="ellipsis dot_2"></div>
                                    <div class="ellipsis dot_3"></div>
                                </div>
                            </div> */}
                        </div>
                        <div class="foot">
                            <input type="text" class="msg" placeholder="Type a message..."
                              value={inputMessageValue}
                              onChange={(e) => setInputMessageValue(e.target.value)}
                            // value={inputMessage}
                            // onChange={(e) => setInputMessage(e.target.value)}
                            onKeyUp={(e) => {
                                if(e.key === 'Enter'){
                                    handleMessageSubmit()
                                }
                            }}
                            />
                            <button type="submit"
                             onClick={handleMessageSubmit}
                             ><i class="mdi mdi-send"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* </SocketProvider> */}
        </>
    )
}
export default AssignmentList