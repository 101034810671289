import React, { useEffect, useLayoutEffect, useState } from 'react'
import AssignmentContainer from '../assignment-request-container/index'
import ResumeExpertContainer from '../resume-expert-container/index'

const OperationsContainer = () => {

const [activeTab, setActiveTab] = useState("Assignment")



const handleTabClick = (tabSelected) => {
    setActiveTab(tabSelected)
}

    return (
        <React.Fragment>
        <div>
            <select onChange={(e) => handleTabClick(e.target.value)} className='m-5 p-2' style={{width:'150px', borderRadius:'10px', border:'1px solid #c5c5c5'}}>
                <option value="Assignment">Assignment</option>
                <option value="Resume">Resume</option>
            </select>
            {/* <div className='tab-header' style={{width:'30%',margin:'2rem auto', display:'flex',justifyContent:'space-between'}}>
                <button className={`tab-btn ${activeTab === 'Assignment' ? "active" : ""}`} onClick={() => handleTabClick("Assignment")}>Assignment</button>
                <button className={`tab-btn ${activeTab === 'Resume' ? "active" : ""}`} onClick={() => handleTabClick("Resume")}>Resume</button>
            </div> */}
            {
                activeTab === 'Assignment' ? (
                    <AssignmentContainer />
                ) : (
                    <ResumeExpertContainer />
                )
            }
        </div>
        </React.Fragment>
    )
}

export default OperationsContainer