import { put, call } from 'redux-saga/effects';
import { cityCreateAPI, citySearchAPI, cityUpdateAPI } from '../../../api/CityApi';
// import { cityCreateAPI, citySearchAPI, cityUpdateAPI } from 'src/api/CityApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
// import { SEARCH_RECORD_PAGE_LIMIT } from '../../../constants/CommonConstants';
// import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';

// import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { takeFirstSagaUtil } from '../../../utils/ReduxSagaUtils';
// import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { buildSearchWithNoCase } from '../../../utils/CommonUtils';
import { ASSIGNMENT_ACTIONS } from '../actions/AssignmentRequestActions';
import { getAssignmentRequestCounts } from 'src/api/AssignmentApi';
// import { getAssignmentRequestCounts } from 'src/api/AssignmentApi';
// import { getAssignmentRequestCounts } from '../../../api/AssignmentApi';


function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(citySearchAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({ _id }) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(citySearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            // DATA NO FOUND
            // yield put(headerShowAction({
            //     data: 'Unable to Edit, something when wrong',
            // }));
        }

        // add in reducer
        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        // yield put(headerShowAction({
        //     data: error.response.data.data,
        // }));

        // yield put(headerShowAction({
        //     data: error.response.data.data,
        // }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(cityCreateAPI, payload);

        // yield put(headerShowAction({
        //     data: 'Created',
        // }));

        // add in reducer
        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        // yield put(headerShowAction({
        //     data: error.response.data.data,
        // }));

        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT_ERROR,
        });
    }
}

function* update(cityId, payload) {
    try {
        yield call(cityUpdateAPI, {
            cityId,
            data: payload,
        });

        // yield put(headerShowAction({
        //     data: 'Updated',
        // }));

        yield edit({ _id: cityId });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        // yield put(headerShowAction({
        //     data: error.response.data.data,
        // }));

        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

function* countExpert() {
    try {
        const {
                data,
            
        } = yield call(getAssignmentRequestCounts);

        yield put({
            type: ASSIGNMENT_ACTIONS.ASSIGNMENT_COUNT,
            assignmentCounts: data,
        });
        return data;
    }
    catch (error) {
        console.error('LOG: function*countAssignment -> error', error);
        // yield put({
        //     type: EXPERT_ACTIONS.EXPERT_SEARCH_ERROR,
        // });
        return null;
    }
}

export default [
    takeFirstSagaUtil(ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH, search),
    takeFirstSagaUtil(ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT, upsert),
    takeFirstSagaUtil(ASSIGNMENT_ACTIONS.ASSIGNMENT_EDIT, edit),
    takeFirstSagaUtil(ASSIGNMENT_ACTIONS.ASSIGNMENT_COUNT, countExpert),
];
