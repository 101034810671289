 import React from 'react'
 
 const WelcomeContainer = () => {
   return (
     <>
       <div className="TopNavN1"> 
<div className="logo"><img src="/assets/images/logo.png" width={'180'} height={'36'}/></div>
 <div className="tabsListN1">
    <ul>
        <li><a href="/"><img src="/assets/images/operations.svg"/> <div className="">Operations</div></a></li>
        <li><a href="/"><img src="/assets/images/supply.svg"/> <div className="">Supply </div></a></li>
        <li><a href="/"><img src="/assets/images/quality.svg"/> <div className="">QA  </div></a></li>
        <li><a href="/"><img src="/assets/images/revenue.svg"/> <div className="">Revenue   </div></a></li>
        <li><a href="/"><img src="/assets/images/finance.svg"/> <div className="">Finance   </div></a></li>
        <li><a href="/"><img src="/assets/images/training.svg"/> <div className="">Training   </div></a></li>
        <li><a href="/"><img src="/assets/images/sale.svg"/> <div className="">Sales   </div></a></li>
        <li><a href="/"><img src="/assets/images/marketing.svg"/> <div className="">Marketing   </div></a></li>
        <li><a href="/"><img src="/assets/images/support.svg"/> <div className="">Support   </div></a></li>
        <li><a href="/"><img src="/assets/images/admin.svg"/> <div className="">Admin   </div></a></li>
        <li><a href="/"><img src="/assets/images/randd.svg"/> <div className="">R&D    </div></a></li>
        <li><a href="/"><img src="/assets/images/reviews.svg"/> <div className="">Reviews   </div></a></li>
        <li><a href="/"><img src="/assets/images/legal.svg"/> <div className="">Legal</div></a></li>
    </ul>
 </div>
 <div className="logout"><a href=""><img src="/assets/images/logout.svg"/></a></div>

       </div>
       <div className="card NameWelcome"> Hello, Shubham </div>
     </>
   )
 }
 
 export default WelcomeContainer
 