import React, { useEffect, useState } from 'react'
import SalesDataTableComponent from './SalesDataTable'
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const Sales = () => {

    const [rangePickerValue, setRangePickerValue] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: new Date(0).toISOString(),
        endDate: new Date().toISOString()
    })

    const handleDateRangeChange = (dates, dateString) => {
        setDateRange({
            startDate: dateString[0],
            endDate: new Date(dateString[1]).setHours(23, 59, 59, 999)
        })
        setRangePickerValue([dates[0], dates[1]])
        console.log('date range picker value is', dates, dateString)
    }
    return (
        <React.Fragment>
            <div style={{ display: 'flex' }}>
                {/* <RangePicker
                    value={rangePickerValue}
                    // value={isResetApplied === true ? []}
                    onChange={(dates, dateString) => handleDateRangeChange(dates, dateString)}
                /> */}
                {/* <button className="gdtnBtnD" onClick = {handleFilterResetClick}>Reset</button>
                <button className="gdtnBtnD ms-3" onClick={handleFilterApplyClick}>Apply</button> */}
            </div>
            <SalesDataTableComponent />
        </React.Fragment>
    )
}

export default Sales