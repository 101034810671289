// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adjust the height based on your preference */
canvas {
    height: 605px;
    /* Set the height of the chart container */
}

.chart-container {
    height: 400px;
    /* Set the height of the chart container */
}`, "",{"version":3,"sources":["webpack://./src/containers/efinder-container/ChartComponent.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;IACI,aAAa;IACb,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,0CAA0C;AAC9C","sourcesContent":["/* Adjust the height based on your preference */\r\ncanvas {\r\n    height: 605px;\r\n    /* Set the height of the chart container */\r\n}\r\n\r\n.chart-container {\r\n    height: 400px;\r\n    /* Set the height of the chart container */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
