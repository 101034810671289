
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


const Navigation = () => {
    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav>
                            <ul className="d-flex gap-3 mt-4">
                                <li><Link to="/email-finder">EMails</Link></li>
                                <li><Link to="/email-finder/search-url">URL List</Link></li>
                                <li><Link to="/email-finder/graph">Stats Graph</Link></li>
                                <li><Link to="/email-finder/mail-templates">Mail Templates</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Navigation