import React, { useEffect, useState } from 'react'
import { getAllFinderUsers, downloadEmail } from 'src/api/EFinderApi'
import Pagination from 'src/components/Pagination'
// import { Link } from 'react-router-dom'
import Navigation from './Navigation'
import NumberComponent from 'src/components/NumberComponent'
import { toast } from 'react-toastify'

const EFinderContainer = () => {

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(0)
    const [dataCountWithEmail, setDataCountWithEmail] = useState(0)
    const [dataWithApolloStatus, setDataWithApolloStatus] = useState(null)
    const [uniqueCompanyCount, setUniqueCompanyCount] = useState(0)
    const [pageNo, setPageNo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState(null)
    const [isAuth, setIsAuth] = useState(true)
    const [error, setError] = useState('')
    const pageLength = 100;
    // const [startDate, setStartDate] = useState(null)
    // const [endDate, setEndDate] = useState(null)

    const formInput = {
        startDate: '',
        endDate: ''
    }
    const [formData, setFormData] = useState(formInput);
    const handleReset = () => {
        // Reset form fields by setting the state to initial values
        setFormData(formInput);
        // setEditId('')
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    useEffect(() => {
        if (isAuth === true) {
            getAllUser()
        }
    }, [pageNo, isAuth])
    useEffect(() => {
        document.title = "EMail | Email Finder"; // Set the document title
    }, []);

    const [showContent, setShowContent] = useState(false);

    const handleDoubleClick = () => {
        setShowContent(!showContent);
    };

    const getAllUser = async () => {
        const res = await getAllFinderUsers({ pageLength: pageLength, currentPage: pageNo })
        setTableData(res.data.data)
        setTotal(res.data.dataCount)
        setDataCountWithEmail(res.data.dataCountWithEmail)
        setDataWithApolloStatus(res.data.dataWithApolloStatus)
        setUniqueCompanyCount(res.data.uniqueCompanyCount)
        setLoading(false)
    }

    const handlePasswordCheck = () => {
        if (password === 'password') {
            setIsAuth(true)
        } else {
            setIsAuth(false)
            setError('Incorrect password.')
        }
    }

    const handleInputEnter = (e) => {
        if (e.key === "Enter") {
            handlePasswordCheck()
        } else {
            return
        }
    }

    const handleCsvSubmit = async (e, e_total) => {
        e.preventDefault();
        try {
            if (e_total === 0) {
                toast.error('Data not available.')
            } else {

                const response = await downloadEmail(formData);
                console.log('Data submitted:', response);
                // Optionally, reset the form fields after successful submission
                // Create a link to download the file

                if (response.data.status === "Failure") {
                    toast.error(response.data.data)
                } else {
                    // const csv = parse(response.data.data);
                    // const url = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
                    const url = window.URL.createObjectURL(new Blob([response.data.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'download.csv');
                    document.body.appendChild(link);
                    link.click();
                }
            }
        } catch (error) {
            alert(error.response.data.message)
            console.error('Error submitting data:', error);
        }
    };

    return (
        <React.Fragment>
            {
                isAuth === true ? (
                    <>
                        <Navigation />
                        <div className="container">
                            <div className="row">
                                <div className='col-12 d-flex justify-content-between mt-4'>
                                    <div className='card p-4 text-center'>
                                        <h5>Total Users: </h5>
                                        <h3><NumberComponent number={total} /></h3>
                                    </div>
                                    <div className='card p-4 text-center' onDoubleClick={handleDoubleClick}>
                                        <h5>Total Email: </h5>
                                        <h3><NumberComponent number={dataCountWithEmail} /></h3>
                                    </div>
                                    <div className='card p-4 text-center'>
                                        <h5>Total Company: </h5>
                                        <h3><NumberComponent number={uniqueCompanyCount} /></h3>
                                    </div>
                                    {showContent && tableData && tableData?.length > 0 &&
                                        <div className='card p-4 text-center'>
                                            <div className="d-flex gap-2 align-items-center my-1">
                                                <span className="dtstart">Start:</span>
                                                <input className="form-select" type="date" name="startDate" value={formData.startDate} onChange={handleChange} />
                                            </div>
                                            <div className="d-flex gap-2 align-items-center my-1">
                                                <span className="dtstart">End:</span>
                                                <input className="form-select" type='date' name="endDate" value={formData.endDate} onChange={handleChange} />
                                            </div>
                                            <button className="btn fw-normal mt-2 text-white" onClick={(e) => handleCsvSubmit(e, dataCountWithEmail)}>Download</button>
                                        </div>
                                    }
                                </div>
                                <div className="col-12 grid-margin  mt-4">
                                    <div className="card">
                                        <div className="card-body ps-0 pe-0">
                                            {
                                                loading === true ? <p>Loading your data....</p> : (
                                                    <div className="table-responsive">
                                                        <table className="table table-center datatablefnts datatable">
                                                            <thead>
                                                                <tr>
                                                                    <th>S. No   </th>
                                                                    <th>Name</th>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Designation</th>
                                                                    <th>Location</th>
                                                                    <th>Business Email</th>
                                                                    <th>Company Name</th>
                                                                    <th>Industry</th>
                                                                    <th>Created At</th>
                                                                    <th>Modified At</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tableData.map((item, idx) => {
                                                                    return (
                                                                        <tr key={idx}>
                                                                            <td>{(pageNo) * 100 + idx + 1}</td>
                                                                            <td>{item.fullName.replace(/"/g, '')}</td>
                                                                            <td>{item.firstName}</td>
                                                                            <td>{item.lastName}</td>
                                                                            <td>{item.designation.replace(/"/g, '')}</td>
                                                                            <td>{item.userLocation.replace(/"/g, '')}</td>
                                                                            <td>{item.business_email}</td>
                                                                            <td>{item.refCompany[0].name}</td>
                                                                            <td>{item.refCompany[0].Industry}</td>
                                                                            <td>{item.createdAt}</td>
                                                                            <td>{item.modifiedAt_pattern}</td>
                                                                        </tr>

                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {tableData && tableData?.length > 0 && <Pagination total={dataCountWithEmail} data={tableData} setPageNo={setPageNo} pageLength={pageLength} pageNo={pageNo} />}

                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    (
                        <div className='card col-3 p-4 mt-5' style={{ margin: '0rem auto' }}>
                            <h2 className='text-center mb-3'>Enter your password</h2>
                            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={(e) => handleInputEnter(e)}
                            />
                            {error !== '' && <p className='mt-3 text-center text-danger'>{error}</p>}
                            <button className='btndaten1 active p-2 w-50 ' style={{ border: 'none', outline: 'none', color: '#fff', background: '#365BE2', margin: '1rem auto' }} onClick={handlePasswordCheck}>Submit</button>
                        </div>
                    )
            }
        </React.Fragment>
    )
}

export default EFinderContainer