import React, { useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
// import { backendBaseURL } from 'src/config/config'

const SocketContext = React.createContext()
export function useSocket() {
  return useContext(SocketContext)
}

export function SocketProvider({ id, children }) {
  const [socket, setSocket] = useState()

  useEffect(() => {
    const newSocket = io(
      // backendBaseURL,
    //   'http://localhost:8000',
      'https://admin.addonstudy.com',
      { query: { id } }
    )
    setSocket(newSocket)

    return () => newSocket.close()
  }, [id])

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  )
}