import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AssignmentRequestDefaultProps } from 'src/containers/assignment-request-container/AssignmentRequestPropTypes';
import { ASSIGNMENT_ACTIONS } from '../actions/AssignmentRequestActions';

const initialState = () => ({
    currentRecord: {...AssignmentRequestDefaultProps},
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    assignmentRequestCounts:{Pending:{count:0,amount:0},Awaiting:{count:0,amount:0},Cancelled:{count:0,amount:0},Delivered:{count:0,amount:0},Rejected:{count:0,amount:0}},
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        // SEARCH
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // UPSERT
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        // COUNT

        case ASSIGNMENT_ACTIONS.ASSIGNMENT_COUNT: {
            return {
                ...state,
                assignmentRequestCounts:{
                    Pending:action?.assignmentCounts?.pendingAssignments,
                    Awaiting:action?.assignmentCounts?.awaitingAssignments,
                    Cancelled:action?.assignmentCounts?.cancelledAssignments,
                    Delivered:action?.assignmentCounts?.deliveredAssignments,
                    Rejected:action?.assignmentCounts?.rejectedAssignments,
                    'In progress':action?.assignmentCounts?.inProgressAssignments
                }
            }
        }

        // RESET
        case ASSIGNMENT_ACTIONS.ASSIGNMENT_RESET: {
            return {
                ...state,
                currentRecord: { ...AssignmentRequestDefaultProps },
            };
        }

        default:
            return state;
    }
};
