import { useState } from 'react';
import TimePeriodFilter from 'src/components/TimePeriodFilter';
import DateTimePickerComponent from 'src/components/date-time-picker-component/DateTimePickerComponent';
import { getDateRange } from 'src/libs/common';

const DateRangeCalendar=({
    onSelect,
    isSeperate=false,
    skips=[]
})=>{
   const [isTimePeriodFilterActive, setIsTimePeriodFilterActive]  = useState(true)
    const handelFilter =(timePeriod)=>{
      
        if(typeof timePeriod==="string")
        {
         setIsTimePeriodFilterActive(false)
          const splitDate = timePeriod.split(' - ')
          let mapWithKey;
          if(isSeperate)
           mapWithKey = {startDate:splitDate[0],endDate:splitDate[1]}
          else
           mapWithKey = {created_at:{$gte:splitDate[0],$lt:splitDate[1]}} 
           console.log('date filter is',mapWithKey)
          onSelect(mapWithKey)
        }
        else{
         setIsTimePeriodFilterActive(true)
          const splitDate = getDateRange(timePeriod);
          let mapWithKey; 
          if(isSeperate)
           mapWithKey = {startDate:splitDate.startDate,endDate:splitDate.endDate}
           else if(timePeriod.timePeriod === 'Today'){
            var start = new Date();
            start.setHours(0, 0, 0, 0);
            var end = new Date();
            end.setHours(23, 59, 59, 999);
            mapWithKey = {created_at:{$gt:start,$lte:end}}
           } 
           else if(timePeriod.timePeriod === 'Yesterday'){
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);
            
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            mapWithKey = {created_at:{ $gte: yesterday,
               $lt: today}}
           } 
          else
           mapWithKey = {created_at:{$gte:splitDate.startDate,$lt:splitDate.endDate}}

          onSelect(mapWithKey)
        }
     }

    return(
        <div className="bgtodnlist">
                           <div className="text-start">
                              <div className="daterangepkr"> 
                                 <span>
                                    <i className="mdi mdi-calendar-check"></i>
                                 </span>
                                 <DateTimePickerComponent
                                    id="demo-12" 
                                    format="YYYY-MM-DD" 
                                    placeholder="start date - end date" 
                                    className="form-control form-control-sm"
                                    singleDate={false}
                                    onChange={
                                       handelFilter}
                                    isTimePeriodFilterActive={isTimePeriodFilterActive}
                                    setIsTimePeriodFilterActive={setIsTimePeriodFilterActive}
                                    // onChange={()=>{handelFilter({date})}}
                                    // value={onChange}
                                 />
                                 {/* <input type="text" id="demo-12" placeholder="start date - end date" className="form-control form-control-sm"/> */}
                              </div>
                           </div>
                           <TimePeriodFilter handelFilter={handelFilter} skips={skips}
                                    isTimePeriodFilterActive={isTimePeriodFilterActive}
                                    setIsTimePeriodFilterActive={setIsTimePeriodFilterActive}
                           />
                        </div>
    )

}
export default DateRangeCalendar