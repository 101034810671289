import React, { useState } from 'react'
import { loginAPI } from 'src/api/UserApi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const LoginContainer = () => {
 const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async() => {
   const res = await loginAPI({email,password})
  //  console.log('login res is', res)
  if(res.status === 203){
   toast.error(`Email or password didn't matched.`)
  }else if(res.status === 200){
    localStorage.setItem('accessToken', res.data.accessToken)
    window.location.href = '/'
  }else{
    toast.error('Some error occured. Please try again.')
  }
  }

  return (
    <>
      <div className="col-md-12">
        <div className="card">
          <div className="Swidth">
            <div className="leftTextbg p24">
              <div className="logo"><img src="/assets/images/logo.png" width={'180'} height={'36'} /></div>
              <div className="textbottom">
                <p>“There is nothing impossible to they who will try” <span className="d-block">~ Alexander The Great </span></p>
              </div>
            </div>
            <div className="formBg p24">
              <div className="formHd">
                <h2>Welcome to dashboard</h2>
                <p>Enter your login credentials to access your cards</p>
              </div>
              <div className="formInput">
                <input type="text" className="form-control" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <input type="password" className="form-control" placeholder="************" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <button type="Button" value={"Login"} className="BtnForm" 
                onClick={handleLogin}
                >Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginContainer
