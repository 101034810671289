import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'antd'
import './index.css'
import moment from 'moment'
import $ from 'jquery';
import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

const UsersContainer = () => {
    const [startDate, setStartDate] = useState(moment().subtract(8, 'days').format('MM/DD/YYYY'));
    const [endDate, setEndDate] = useState(moment().format('MM/DD/YYYY'));
    const [showFilterPopup, setShowFilterPopup] = useState(false)
    const [showManageColumnsPopup, setShowManageColumnsPopup] = useState(false)


    useEffect(() => {
        // Define predefined ranges
        const predefinedRanges = {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        };

        // Initialize the date range picker with predefined ranges
        $('#demo').daterangepicker({
            startDate: startDate,
            endDate: endDate,
            ranges: predefinedRanges, // Specify predefined ranges here
            showCancelButton: false, // Hide the cancel button
            showApplyButton: false,
        }, function (start, end, label) {
            // Update component state when date range changes
            setStartDate(start.format('MM/DD/YYYY'));
            setEndDate(end.format('MM/DD/YYYY'));
        });

        // Handle the event when the Apply button is clicked
        $('#demo').on('apply.daterangepicker', function (ev, picker) {
            // Update component state with the final selected date range
            setStartDate(picker.startDate.format('MM/DD/YYYY'));
            setEndDate(picker.endDate.format('MM/DD/YYYY'));
        });
    }, []);

    return (
        <React.Fragment>
            <head>
                {/* <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" /> */}
                {/* <link href="https://cdn.datatables.net/1.13.7/css/dataTables.bootstrap5.min.css" rel="stylesheet" /> */}
                {/* <link href="https://cdn.datatables.net/buttons/2.4.2/css/buttons.bootstrap5.min.css" rel="stylesheet" /> */}
                {/* <link href="assets/css/mdi/css/materialdesignicons.min.css" rel="stylesheet" /> */}
            </head>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 py-3">
                        <a href="#"><img src="assets/images/logo.svg" class="img-fluid" alt="" /></a>
                    </div>
                </div>
            </div>
            <section class="sec UseroverviewBg">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-between align-items-center bannerMvw">
                            <div class="">
                                <h1>User overview</h1>
                            </div>
                            <div class="">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="TimeDate"><span class="Time">2:30pm</span>&#44;<span class="DateMonthYear">27/Jan/2023</span><div class="day">Tuesday</div></div>
                                    <div>
                                        <input type="text" id="demo" style={{
                                            background: 'none',
                                            outline: 'none',
                                            border: '1px solid grey',
                                            borderRadius: '5px',
                                            padding: '12px',
                                            color: 'white'
                                        }}
                                            value={`${startDate} - ${endDate}`}
                                        />
                                        <p>Selected Date Range: {startDate} to {endDate}</p>
                                    </div>
                                </div>
                                <div class="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section>

            </section> */}
            <Row style={{width:'60%'}}>
                <Col span={10}>
                <Card title="Card title" bordered={false}>Card content</Card>
                </Col>
                <Col span={1}/>
                <Col span={10}><Card title="Card title" bordered={false}>
                    <Row justify='space-evenly' align= 'center'>
                        <Col span={12} align='center' justify='center'>25/Aug/23</Col>
                        <Col span ={12} align='center' justify='center'>25/Aug/23</Col>
                    </Row>
                    <Row justify='space-evenly' align= 'center'>
                        <Col span={12} align='center' justify='center'>25/Aug/23</Col>
                        <Col span ={12} align='center' justify='center'>25/Aug/23</Col>
                    </Row>
                    <Row justify='space-evenly' align= 'center'>
                        <Col span={12} align='center' justify='center'>25/Aug/23</Col>
                        <Col span ={12} align='center' justify='center'>25/Aug/23</Col>
                    </Row>
                    <Row justify='space-evenly' align= 'center'>
                        <Col span={12} align='center' justify='center'>25/Aug/23</Col>
                        <Col span ={12} align='center' justify='center'>25/Aug/23</Col>
                    </Row>
                    </Card>
                    </Col>
            </Row>
        </React.Fragment>
    )
}

export default UsersContainer