import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd';
import DataTableComponent from './DataTableComponent';
import { getStudentCounts, supportSearchAPI } from 'src/api/SupportApi';
import { getProspectingCounts, getProspectingDispositionDetails, getProspectingLeadStageDetails } from 'src/api/ProspectingDetailApi';
import { getChartDateRange } from 'src/libs/common';
import Chart from 'chart.js/auto';
import { Line, Doughnut, Bar } from "react-chartjs-2";



const { RangePicker } = DatePicker;

const CustomCheckbox = ({ label, id, moduleTabSelected, setModuleTabSelected, value }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(!isChecked);
        if (e.target.checked === true) {
            setModuleTabSelected((prev) => [...prev, {
                'query_type': {
                    '$regex': value,
                    '$options': 'i'
                }
            }])
        } else {
            const remove_qt = moduleTabSelected.filter((item) => item['query_type']['$regex'] !== value)
            setModuleTabSelected(remove_qt)
            console.log('remove_qt', remove_qt)
        }
    };

    return (
        <div className="custom-checkbox-container">
            <input
                type="checkbox"
                id={id}
                checked={isChecked && moduleTabSelected.length !== 0}
                onChange={(e) => handleCheckboxChange(e)}
            />
            <label htmlFor={id} className="custom-checkbox-label">
                {isChecked ? '' : ''} {label}
            </label>
        </div>
    );
};
const TabButtons = ({ tabs, activeTab, onTabChange }) => {
    return (
        <div className="tabs-container">
            {tabs.map((tab) => (
                <button
                    key={tab.id}
                    onClick={() => onTabChange(tab.id, tab.label)}
                    className={tab.id === activeTab ? 'active' : ''}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );
};


// modal start 
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content modal-lg viewdtal p30">
                <span className="close-btn" onClick={onClose}>&times;</span>
                {children}
            </div>
        </div>
    );
};
// modal end 

const Support = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [tableData, setTableData] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [moduleTabSelected, setModuleTabSelected] = useState([])
    const [rangePickerValue, setRangePickerValue] = useState([])
    const [isResetApplied, setIsResetApplied] = useState(false)
    const [barChartLabels, setBarChartLabels] = useState([])
    const [filter, setFilter] = useState({
        searchValue: "", module: [{
            'query_type': {
                '$regex': 'resume',
                '$options': 'i'
            }
        }, {
            'query_type': {
                '$regex': 'assignment',
                '$options': 'i'
            }
        },
        {
            'query_type': {
                '$regex': 'others',
                '$options': 'i'
            }
        },
        {
            'query_type': {
                '$regex': 'signup',
                '$options': 'i'
            }
        }
        ], dateRange: {
            startDate: new Date(0),
            endDate: new Date()
        }
    })
    const [dateRange, setDateRange] = useState({
        startDate: new Date(0).toISOString(),
        endDate: new Date().toISOString()
    })
    const [dispositionArr, setDispositionArr] = useState([])
    const [leadStageArr, setLeadStageArr] = useState([])
    const [prospectingDateRangeSelected, setProspectingDateRangeSelected] = useState('Daily')
    const [signupDateRangeSelected, setSignupDateRangeSelected] = useState('Daily')
    const [prospectingCountArr, setProspectingCountArr] = useState([])
    const [studentCountArr, setStudentCountArr] = useState([])
    const [contactusCountArr, setContactusCountArr] = useState([])
    const [leadFormCountArr, setLeadFormCountArr] = useState([])
    const [hoveredValue, setHoveredValue] = useState(null);
    const [lineChartLabels, setLineChartLabels] = useState([])
    const [hireExpertCountArr, setHireExpertCountArr] = useState([])
    const [manageColumnsArr, setManageColumnsArr] = useState([])
    const [isManageColumnApplied, setIsManageColumnApplied] = useState(false)

    useEffect(() => {
        const weeklydates = generateDates('weekly')
        setBarChartLabels(weeklydates)
        handleDateRangeFilter("Daily")
    }, [])

    useEffect(() => {
        // const weeklydates = generateDates('weekly')
        // setLineChartLabels(weeklydates)
        handleLineChartDateRangeFilter("Daily")
    }, [])

    useEffect(() => {
        fetchSupportData()
    }, [filter])

    useEffect(() => {
        prospectingDispositionPieChartDetails()
        prospectingLeadStagePieChartDetails()
    }, [])

    const prospectingDispositionPieChartDetails = async () => {
        let temp_arr = []
        const res = await getProspectingDispositionDetails()
        const dataObject = res.data
        for (let key in dataObject) {
            temp_arr.push(dataObject[key]);
        }
        setDispositionArr(temp_arr)
    }

    const prospectingLeadStagePieChartDetails = async () => {
        let temp_arr = []
        const res = await getProspectingLeadStageDetails()
        const dataObject = res.data
        for (let key in dataObject) {
            temp_arr.push(dataObject[key]);
        }
        setLeadStageArr(temp_arr)
    }

    const fetchSupportData = async () => {
        const res = await supportSearchAPI(filter)
        setTableData(res?.data?.data)
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const [activeDiv, setActiveDiv] = useState(null);

    const handleButtonClick = (divId) => {
        setActiveDiv((prevActiveDiv) => (prevActiveDiv === divId ? null : divId));
    };

    const tabs = [
        { id: 'tab1', label: 'All Time' },
        { id: 'tab2', label: 'Today' },
        { id: 'tab3', label: 'Yesterday' },
        { id: 'tab4', label: 'Last Week' },
        { id: 'tab5', label: 'Last Month' },
        { id: 'tab6', label: 'Last Year' },
    ];

    const [activeTab, setActiveTab] = useState('');

    const handleTabChange = (tabId, tabLabel) => {
        setActiveTab(tabId);
        const splitDate = getChartDateRange({ timePeriod: tabLabel })
        console.log('splitDate', splitDate, tabLabel)
        setDateRange({
            startDate: splitDate.startDate,
            endDate: splitDate.endDate
        })
    };

    //   custom check box start
    const checkboxesData = [
        { id: 'checkbox1', label: 'Resume', value: 'resume' },
        { id: 'checkbox2', label: 'Assignment', value: 'assignment' },
        { id: 'checkbox3', label: 'Others', value: 'others' },
        { id: 'checkbox4', label: 'SignUp', value: 'signup' }
    ];
    //  custom check box end 

    // modal popup code start
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    // modal popup code end

    const handleInputSearch = () => {
        setFilter({ ...filter, searchValue: searchValue })
    }

    const handleDateRangeChange = (dates, dateString) => {
        setActiveTab(null)
        setDateRange({
            startDate: dateString[0],
            endDate: new Date(dateString[1]).setHours(23, 59, 59, 999)
        })
        setRangePickerValue([dates[0], dates[1]])
    }
    // var end = new Date();
    // end.setHours(23, 59, 59, 999);
    const handleApplyFilterClick = () => {
        setFilter({
            ...filter, dateRange: { startDate: new Date(dateRange.startDate).toISOString(), endDate: new Date(dateRange.endDate) }, module: moduleTabSelected.length === 0 ? [{
                'query_type': {
                    '$regex': 'resume',
                    '$options': 'i'
                }
            }, {
                'query_type': {
                    '$regex': 'assignment',
                    '$options': 'i'
                }
            },
            {
                'query_type': {
                    '$regex': 'others',
                    '$options': 'i'
                }
            },
            {
                'query_type': {
                    '$regex': 'signup',
                    '$options': 'i'
                }
            }
            ] : moduleTabSelected
        })
        setSearchValue('')
        console.log('handleApplyFilterClick', { searchValue: '', dateRange: { $gte: dateRange.startDate, $lte: dateRange.endDate }, module: moduleTabSelected })
    }

    const handleResetFilterClick = () => {
        setModuleTabSelected([])
        setFilter({
            searchValue: "", module: [{
                'query_type': {
                    '$regex': 'resume',
                    '$options': 'i'
                }
            }, {
                'query_type': {
                    '$regex': 'assignment',
                    '$options': 'i'
                }
            },
            {
                'query_type': {
                    '$regex': 'others',
                    '$options': 'i'
                }
            },
            {
                'query_type': {
                    '$regex': 'signup',
                    '$options': 'i'
                }
            }
            ], dateRange: {
                startDate: new Date(0),
                endDate: new Date()
            }
        })
        setDateRange({
            startDate: '',
            endDate: ''
        })
        setRangePickerValue([])
    }

    const labels = ["January", "February", "March", "April", "May", "June", "July", 'August', 'September', 'October', 'November', 'December'];

    const data = {
        labels: barChartLabels,
        datasets: [
            {
                label: '',
                backgroundColor: "rgb(255, 155, 82)",
                borderColor: "rgb(255, 155, 82)",
                data: prospectingCountArr,
            },
        ],
    };

    const lineChartData = {
        labels: lineChartLabels,
        datasets: [
            {
                label: 'Signup',
                backgroundColor: "rgb(255, 155, 82)",
                borderColor: "rgb(255, 155, 82)",
                data: studentCountArr,
            },
            {
                label: 'Contact Us',
                backgroundColor: "rgb(63, 154, 224)",
                borderColor: "rgb(63, 154, 224)",
                data: contactusCountArr,
            },
            {
                label: 'Lead Form',
                backgroundColor: "rgb(142, 152, 168)",
                borderColor: "rgb(142, 152, 168)",
                data: leadFormCountArr,
            },
            {
                label: 'Hire Expert',
                backgroundColor: "rgb(0,128,128)",
                borderColor: "rgb(0,128,128)",
                data: hireExpertCountArr,
            }
        ],
    };

    const plugins = [
        {
            afterDraw: function (chart, args, options) {
                const { ctx, chartArea: { left, right, top, bottom, width, height } } = chart;
                ctx.save()
                if (chart._active.length > 0) {
                    const textLabel = chart.config.data.labels[chart._active[0].index]
                    const numberLabel = chart.config.data.datasets[chart._active[0].datasetIndex].data[chart._active[0].index]
                    console.log('chart', chart._active[0].index)
                    ctx.font = 'bold 30px Arial'
                    ctx.fillStyle = 'black'
                    ctx.textAlign = 'center'
                    ctx.fillText(numberLabel, width / 2, height / 2 + top)
                }
            }
        }
    ]

    const plugins2 = [
        {
            afterDraw: function (chart, args, options) {
                const { ctx, chartArea: { left, right, top, bottom, width, height } } = chart;
                ctx.save()
                if (chart._active.length > 0) {
                    const textLabel = chart.config.data.labels[chart._active[0].index]
                    const numberLabel = chart.config.data.datasets[chart._active[0].datasetIndex].data[chart._active[0].index]
                    console.log('chart', chart._active[0].index)
                    ctx.font = 'bold 30px Arial'
                    ctx.fillStyle = 'black'
                    ctx.textAlign = 'center'
                    ctx.fillText(numberLabel, width / 2, height / 2 + top)
                }
            }
        }
    ]

    const leadStagePieChart = {
        labels: [
            'Not interested',
            'Casually Browsing',
            'Interested',
            'Connected',
            'Hot',
            'Nurture: 1 month',
            'Nurture: 3 month',
            'Converted',
            'Not Valid',
            'DNP',
            'YTC'
        ],
        datasets: [{
            data: leadStageArr,
            backgroundColor: [
                '#EBDCFF',
                '#2BC155',
                '#5C5C5C',
                '#4E36E2',
                '#FF9B52',
                '#3F9AE0',
                '#8E98A8',
                '#FFE8D8',
                '#176B87',
                "#4F6F52",
                "#5F0F40"
            ],
            //   borderWidth: 0,
            radius: '100%',
            //   hoverOffset: 50,
            //   spacing:0
            borderWidth: 0,
            hoverOffset: 0,
        }]
    };
    const dispositonPieChart = {
        labels: [
            'Yet to Dial',
            'Do Not Pick',
            'Invalid Contact',
            'Connected',
            'Do Not Contact',
            'Callback',
            'Not Interested',
            'Abruptly Disconnected',
            'Requires Follow-Up'
        ],
        datasets: [{
            data: dispositionArr,
            backgroundColor: [
                '#EBDCFF',
                '#2BC155',
                '#5C5C5C',
                '#4E36E2',
                '#FF9B52',
                '#3F9AE0',
                '#8E98A8',
                '#FFE8D8',
                '#176B87'
            ],
            borderWidth: 0,
            radius: '60%',
            hoverOffset: 0,
            hoverBorderDashOffset: 0,
            spacing: 0,
            offset: 0
        }]
    };

    const mergeChartDataByRange = (arr, range) => {
        let sum = 0
        let temp_arr = []
        let dataPart = 0
        if (range === 'Last Week') {
            return arr
        } else if (range === 'Days_15') {
            return arr
        } else if (range === 'Last Month') {
            while (dataPart <= 5) {
                temp_arr.push(sum += arr[dataPart])
                dataPart++;
            }
            dataPart = 0
        }
    }

    const handleLineChartDateRangeFilter = async (targetValue) => {
        if (targetValue === 'Monthly') {
            // Example usage:
            const monthlyDates = generateDates('monthly');
            console.log('Monthly Dates:', monthlyDates);
            setLineChartLabels(monthlyDates)
        } else if (targetValue === 'Daily') {
            const weeklyDates = generateDates('weekly');
            setLineChartLabels(weeklyDates)
        }
        else if (targetValue === 'Last 14 days') {
            const weeklyDates = generateDates('Last 14 days')
            setLineChartLabels(weeklyDates)
        }
        else {
            setLineChartLabels(["January", "February", "March", "April", "May", "June", "July", 'August', 'September', 'October', 'November', 'December'])
        }
        let count_arr = []
        let contact_count_arr = []
        let lead_form_count_arr = []
        let hire_expert_count_arr = []
        let startDate = new Date();
        let endDate = new Date();
        setSignupDateRangeSelected(targetValue)
        const time_range = getChartDateRange({ timePeriod: targetValue })
        console.log('graph time is', time_range, targetValue)
        const { data: { data, contactus_data, leadform_data, hire_expert_data } } = await getStudentCounts({ timePeriod: targetValue, startDate: time_range.startDate, endDate: time_range.endDate })
        startDate = new Date(time_range.startDate)
        endDate = new Date(time_range.endDate)
        while (startDate <= endDate) {
            startDate = new Date(startDate.setDate(startDate.getDate() + 1))
            console.log('start date', startDate)
        }
        data[0]['combinedResults'].forEach((item) => {
            count_arr.push(item.count)
        })
        contactus_data[0]['combinedResults'].forEach((item) => {
            contact_count_arr.push(item.count)
        })
        leadform_data[0]['combinedResults'].forEach((item) => {
            lead_form_count_arr.push(item.count)
        })
        hire_expert_data[0]['combinedResults'].forEach((item) => {
            hire_expert_count_arr.push(item.count)
        })
        if(targetValue === 'Daily'){
        // setStudentCountArr(count_arr.slice(new Date(time_range.startDate).getDay()))
        // setContactusCountArr(contact_count_arr.slice(new Date(time_range.startDate).getDay()))
        // setLeadFormCountArr(lead_form_count_arr.slice(new Date(time_range.startDate).getDay()))
        setStudentCountArr(count_arr.slice(new Date(time_range.endDate).getDate()-7))
        setContactusCountArr(contact_count_arr.slice(new Date(time_range.endDate).getDate()-7))
        setLeadFormCountArr(lead_form_count_arr.slice(new Date(time_range.endDate).getDate()-7))
        setHireExpertCountArr(hire_expert_count_arr.slice(new Date(time_range.endDate).getDate()-7))
        console.log('line chart details is', new Date(time_range.endDate).getDate()-7)
        // setStudentCountArr(count_arr.slice(0,8))
        // setContactusCountArr(contact_count_arr.slice(0,8))
        // setLeadFormCountArr(lead_form_count_arr.slice(0,8))
        }else {
            setStudentCountArr(count_arr)
            setContactusCountArr(contact_count_arr)
            setLeadFormCountArr(lead_form_count_arr)
            setHireExpertCountArr(hire_expert_count_arr)
        }
    }

    const handleDateRangeFilter = async (targetValue) => {
        if (targetValue === 'Monthly') {
            // Example usage:
            const monthlyDates = generateDates('monthly');
            setBarChartLabels(monthlyDates)
        } else if (targetValue === 'Daily') {
            const weeklyDates = generateDates('weekly');
            setBarChartLabels(weeklyDates)
        }
        else if (targetValue === 'Last 14 days') {
            const weeklyDates = generateDates('Last 14 days')
            setBarChartLabels(weeklyDates)
        }
        else {
            setBarChartLabels(["January", "February", "March", "April", "May", "June", "July", 'August', 'September', 'October', 'November', 'December'])
        }
        let count_arr = []
        let startDate = new Date();
        let endDate = new Date();
        setProspectingDateRangeSelected(targetValue)
        const time_range = getChartDateRange({ timePeriod: targetValue })
        console.log('time_range', time_range)
        const { data: { data } } = await getProspectingCounts({ timePeriod: targetValue, startDate: time_range.startDate, endDate: time_range.endDate })
        startDate = new Date(time_range.startDate)
        endDate = new Date(time_range.endDate)
        while (startDate <= endDate) {
            startDate = new Date(startDate.setDate(startDate.getDate() + 1))
            console.log('start date', startDate)
        }
        data[0]['combinedResults'].forEach((item) => {
            count_arr.push(item.count)
        })
        setProspectingCountArr(count_arr)
    }
    const options = {
        tooltips: {
            enabled: false,
        },
        legend: {
            display: false,
        },
        width: 100
    };

    function generateDates(type) {
        const result = [];
        const currentDate = new Date(); // Get the current date
        const daysInWeek = 7;

        if (type === 'monthly') {
            let startDate = new Date()
            let endDate = new Date()
            startDate = startDate.setDate(currentDate.getDate() + 1 - 30)
            endDate = endDate.setDate(currentDate.getDate() - 1)
            // Generate an array of weekly start dates for the current month
            //   const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            //   const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            //   let currentDay = firstDayOfMonth;

            //   while (currentDay <= lastDayOfMonth) {
            //     result.push(currentDay.toLocaleDateString());
            //     currentDay.setDate(currentDay.getDate() + daysInWeek);
            //   }
            while (startDate <= endDate) {
                result.push(new Date(startDate).toISOString().slice(0, 10));
                startDate = new Date(startDate).setDate(new Date(startDate).getDate() + daysInWeek);
            }
        } else if (type === 'weekly') {
            // Generate an array of all days' dates for the current week
            //   const currentDayOfWeek = currentDate.getDay();
            //   const firstDayOfWeek = new Date(currentDate);
            //   firstDayOfWeek.setDate(currentDate.getDate() - currentDayOfWeek);
            //   let currentDay = new Date(firstDayOfWeek);

            //   for (let i = 0; i < daysInWeek; i++) {
            //     result.push(currentDay.toLocaleDateString());
            //     currentDay.setDate(currentDay.getDate() + 1);
            //   }
            let startDate = new Date()
            let endDate = new Date()
            startDate = startDate.setDate(currentDate.getDate() + 1 - 7)

            // endDate = endDate.setHours(23,59,59,999)
            for (let i = 0; i < daysInWeek; i++) {
                result.push(new Date(startDate).toISOString().slice(0, 10));
                startDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1);
            }
        }
        else if (type === 'Last 14 days') {
            let startDate = new Date()
            let endDate = new Date()
            startDate = startDate.setDate(currentDate.getDate() + 1 - 15)

            // endDate = endDate.setHours(23,59,59,999)
            for (let i = 0; i < 15; i++) {
                result.push(new Date(startDate).toISOString().slice(0, 10));
                startDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1);
            }
        }
        else {
            console.error('Invalid type. Please use "monthly" or "weekly".');
        }
        console.log('result is', result)
        return result;
    }

    const handleToggleColumnClick = (targetValue, targetBool) => {
        if(targetBool === false){
            const filteredData = manageColumnsArr.filter((item) => item !== targetValue)
            setManageColumnsArr(filteredData)
        }else {
            setManageColumnsArr((prev) => [...prev, targetValue])
        }
    }

    const handleApplyManageColumn = () => {
        setIsManageColumnApplied(true)
    }

    const handleResetManageColumn = () => {
        setIsManageColumnApplied(false)
        setManageColumnsArr([])
    }


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="col-md-12">
                        <div className="hddingtop mb-3">
                            <h3 style={{ marginBottom: '4rem' }}>Quick stats</h3>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <div style={{ height: '50%', width: '40%', position: 'relative' }}>
                                    <h2 style={{ marginBottom: '1rem' }}>Signup Overview</h2>
                                    <Line data={lineChartData}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: true
                                                }
                                            }
                                        }}
                                    />
                                    <select onChange={(e) => handleLineChartDateRangeFilter(e.target.value)} style={{ position: 'absolute', top: -15, right: 30, width: '200px', padding: '7px', border: 'none', background: '#fff', outline: 'none', boxShadow: '0px 0px 4px 4px #0000001a', borderRadius: '5px' }}>
                                        <option value="Daily">7 days</option>
                                        <option value="Last 14 days">2 weeks</option>
                                        <option value="Monthly">1 month</option>
                                        <option value="Yearly">1 year</option>
                                        {/* <option>Last year</option> */}
                                    </select>
                                </div>
                                <div style={{ height: '50%', width: '40%', position: 'relative' }}>
                                    <h2 style={{ marginBottom: '1rem' }}>Prospecting Overview</h2>
                                    <Bar data={data} options={{
                                        plugins: {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />
                                    <select onChange={(e) => handleDateRangeFilter(e.target.value)}
                                        style={{ position: 'absolute', top: -15, right: 30, width: '200px', padding: '7px', border: 'none', background: '#fff', outline: 'none', boxShadow: '0px 0px 4px 4px #0000001a', borderRadius: '5px' }}
                                    >
                                        <option value="Daily">7 days</option>
                                        <option value="Last 14 days">2 weeks</option>
                                        <option value="Monthly">1 month</option>
                                        <option value="Yearly">1 year</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '5rem', justifyContent: 'space-evenly' }}>
                                {/* <div style={{
                                    width: '500px', backgroundColor: '#FFF',
                                    padding: '20px', boxShadow: '0px 0px 4px 4px #0000001a', borderRadius: '14px'
                                }}>
                                    <h2 style={{ textAlign: 'center' }}>Prospecting Disposition</h2>
                                    <Doughnut data={dispositonPieChart} plugins={plugins}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                    position: 'bottom'
                                                }, tooltips: {
                                                    enabled: false,
                                                    display: false,
                                                }, layout: {
                                                    margin: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                },
                                                plotOptions: {
                                                    polarArea: {
                                                        rings: {
                                                            strokeWidth: 0
                                                        },
                                                        spokes: {
                                                            strokeWidth: 0
                                                        },
                                                    }
                                                },
                                                chart: {
                                                    width: 400,
                                                    type: 'polarArea'
                                                }
                                            },
                                            plotOptions: {
                                                polarArea: {
                                                    rings: {
                                                        strokeWidth: 0
                                                    },
                                                    spokes: {
                                                        strokeWidth: 0
                                                    },
                                                }
                                            },
                                            chart: {
                                                width: 400,
                                                type: 'polarArea'
                                            }
                                        }}
                                        style={{ marginTop: '-3rem' }}
                                    />
                                    <div className='dougnut-chart-legend-grid'>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#EBDCFF' }}></i>Yet to Dial</span>
                                            <span>{dispositionArr?.[0]}</span>
                                        </h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#2BC155' }}></i>Do Not Pick</span><span>{dispositionArr?.[1]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#5C5C5C' }}></i>Invalid Contact</span><span>{dispositionArr?.[2]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#4E36E2' }}></i>Connected</span><span>{dispositionArr?.[3]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#FF9B52' }}></i>Do Not Contact</span><span>{dispositionArr?.[4]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#3F9AE0' }}></i>Callback</span><span>{dispositionArr?.[5]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#8E98A8' }}></i>Not interested</span><span>{dispositionArr?.[6]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#FFE8D8' }}></i>Abruptly Disconnected</span><span>{dispositionArr?.[7]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#176B87' }}></i>Requires Follow-Up</span><span>{dispositionArr?.[8]}</span></h6>
                                    </div>
                                </div> */}
                                <div style={{
                                    width: '1000px', backgroundColor: '#FFF',height:'600px',
                                    padding: '20px', boxShadow: '0px 0px 4px 4px #0000001a', borderRadius: '14px'
                                }}>
                                    <h2 style={{ margin: '0rem', textAlign: 'center' }}>Prospecting Lead Stage</h2>
                                    <div style={{display:'flex', width:'100%', justifyContent:'space-evenly',marginTop:'5rem'}}>
                                    <Doughnut data={leadStagePieChart}
                                        style={{ marginTop: '-3rem' }}
                                        options={{
                                            plugins: {
                                                spacing: 2,
                                                legend: {
                                                    display: false,
                                                }, tooltips: {
                                                    enabled: false,
                                                }, layout: {
                                                    margin: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                }
                                            },

                                        }} plugins={plugins2} />
                                    <div className='dougnut-chart-legend-grid'>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#EBDCFF' }}></i>Yet To Dial</span>
                                            <span>{leadStageArr?.[0]}</span>
                                        </h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#2BC155' }}></i>Did Not Pick</span><span>{leadStageArr?.[1]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#5C5C5C' }}></i>Invalid Number</span><span>{leadStageArr?.[2]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#4E36E2' }}></i>Not Connected</span><span>{leadStageArr?.[3]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#FF9B52' }}></i>Disconnected</span><span>{leadStageArr?.[4]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#3F9AE0' }}></i>Call Busy</span><span>{leadStageArr?.[5]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#8E98A8' }}></i>Service Purchased</span><span>{leadStageArr?.[6]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#FFE8D8' }}></i>Referral Provided</span><span>{leadStageArr?.[7]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#176B87' }}></i>Call Rescheduled</span><span>{leadStageArr?.[8]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#4F6F52' }}></i>Casually Browsing</span><span>{leadStageArr?.[9]}</span></h6>
                                        <h6> <span><i class='mdi mdi-circle' style={{ color: '#5F0F40' }}></i>Not Interested</span><span>{leadStageArr?.[10]}</span></h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stickyTFx">
                            <div className="d-flex justify-content-between align-items-center">
                                <div id="custom-search" className="d-flex custom-search">
                                    <input type="search" id="search" className="form-control" placeholder="Search by name, phone, or email"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                    <button onClick={handleInputSearch}><i className="mdi mdi-magnify"></i></button>
                                </div>
                                <div>
                                <button type="button" className="gdtnBtnD mt-4 me-3" data-bs-toggle="modal" data-bs-target="#ManageColumn">Manage Column</button>
                                <button className="FilterBtn" onClick={() => handleButtonClick('div1')}><i className="mdi mdi-filter"></i> Filter</button>
                                </div>
                            </div>
                        </div>
                        <div className={`filterCls ${activeDiv === 'div1' ? 'active' : 'hide'}`}>
                            <div className="stepDate1">
                                <div className="DateSelet">
                                    <label className="nm321">Select Date </label>
                                    <div className="d-flex"><span className="me-2">Date</span>
                                        {/* <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable
                                            placeholderText="dd/mm/yy"
                                        /> */}
                                        <RangePicker
                                            value={rangePickerValue}
                                            // value={isResetApplied === true ? []}
                                            onChange={(dates, dateString) => handleDateRangeChange(dates, dateString)}
                                        />
                                    </div>
                                </div>
                                <div className="tabsDateBtn"><TabButtons tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} /></div>
                            </div>
                            <div className="stepDate2">
                                <div className="selectM">
                                    {/* <label className="nm321">Select Module </label>
                                    <div className="d-flex align-items-center custBoxs justify-content-between"> {checkboxesData.map((checkbox) => (
                                        <CustomCheckbox key={checkbox.id} id={checkbox.id} label={checkbox.label} setModuleTabSelected={setModuleTabSelected}
                                            value={checkbox.value}
                                            moduleTabSelected={moduleTabSelected}
                                        />
<<<<<<< HEAD
                                    ))}</div> */}
                                    <div className="text-end mt-5">
                                        <button className="gdtnBtnD" onClick={handleResetFilterClick}>Reset</button>
                                        <button className="gdtnBtnD ms-3" onClick={handleApplyFilterClick}>Apply</button>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <p>Manage Columns</p>
                                
                            </div> */}
                        </div>
                        <DataTableComponent openModal={openModal} tableData={tableData} 
                        manageColumnsArr={manageColumnsArr}
                        isManageColumnApplied={isManageColumnApplied}
                        />
                        <Modal isOpen={isModalOpen} onClose={closeModal}>
                            <h2>View Details</h2>
                            <ul className="tbVlist">
                                <li><span>Name :</span> Nikita Gupta </li>
                                <li><span> Date  :</span>  30-03-2008 </li>
                                <li> <span>Module :</span> Resume</li>
                                <li> <span>Phone:</span>  +91 - 9876543210 </li>
                                <li> <span>Email Id :</span>  nikita@acadecraft.net </li>
                                <li> <span>Notes :</span>   I want to hire an expert for building my resume for the internship purposes</li>
                            </ul>
                        </Modal>
                    </div>
                </div>
            </div>



            <div class="modal fade hdsamn1" id="ManageColumn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                // style={{ display: showManageColumnsPopup === true ? 'block' : 'none' }}
            >
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title hdbsh1">Manage Column</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="BoxMangeColum">
                                        <label class="checkBox"> Date
                                            <input type="checkbox" checked = {manageColumnsArr.includes('date')}
                                            onClick={(e) => handleToggleColumnClick('date',e.target.checked)}  />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Notes <input type="checkbox" 
                                        checked = {manageColumnsArr.includes('notes')}
                                        onClick={(e) => handleToggleColumnClick('notes',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Time
                                            <input type="checkbox" checked = {manageColumnsArr.includes('time')}
                                            onClick={(e) => handleToggleColumnClick('time',e.target.checked)} 
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">View Details <input type="checkbox" 
                                        checked = {manageColumnsArr.includes('view')}
                                        onClick={(e) => handleToggleColumnClick('view',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Query Type
                                            <input type="checkbox" checked = {manageColumnsArr.includes('query_type')}
                                            onClick={(e) => handleToggleColumnClick('query_type',e.target.checked)}/>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Prospecting Date <input type="checkbox" 
                                        checked = {manageColumnsArr.includes('prospecting_date')}
                                        onClick={(e) => handleToggleColumnClick('prospecting_date',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Module
                                            <input type="checkbox" checked = {manageColumnsArr.includes('module')}
                                            onClick={(e) => handleToggleColumnClick('module',e.target.checked)} />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Prospecting Time 	<input type="checkbox" 
                                        checked = {manageColumnsArr.includes('prospecting_time')}
                                        onClick={(e) => handleToggleColumnClick('prospecting_time',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">First Name  <input type="checkbox" checked = {manageColumnsArr.includes('first_name')}
                                            onClick={(e) => handleToggleColumnClick('first_name',e.target.checked)} />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Prospecting Type <input type="checkbox" 
                                        checked = {manageColumnsArr.includes('prospecting_type')}
                                        onClick={(e) => handleToggleColumnClick('prospecting_type',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Last Name <input type="checkbox"
                                        checked = {manageColumnsArr.includes('last_name')}
                                        onClick={(e) => handleToggleColumnClick('last_name',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Prospecting Frequency	<input type="checkbox" 
                                        checked = {manageColumnsArr.includes('prospecting_frequency')}
                                        onClick={(e) => handleToggleColumnClick('prospecting_frequency',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Phone <input type="checkbox" 
                                        checked = {manageColumnsArr.includes('phone')}
                                        onClick={(e) => handleToggleColumnClick('phone',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Lead Stage	<input type="checkbox" 
                                        checked = {manageColumnsArr.includes('lead_stage')}
                                        onClick={(e) => handleToggleColumnClick('lead_stage',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Country <input type="checkbox" 
                                        checked = {manageColumnsArr.includes('country')}
                                        onClick={(e) => handleToggleColumnClick('country',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Feedback	<input type="checkbox" 
                                        checked = {manageColumnsArr.includes('feedback')}
                                        onClick={(e) => handleToggleColumnClick('feedback',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checkBox">Email ID  	<input type="checkbox" 
                                        checked = {manageColumnsArr.includes('email')}
                                        onClick={(e) => handleToggleColumnClick('email',e.target.checked)}
                                        />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="db_table_colbtn text-center mt-4">
                                        <button class="GdtnBtn me-3" data-bs-dismiss="modal" onClick={handleApplyManageColumn}>Apply</button>
                                    <button class="GdtnBtn" data-bs-dismiss="modal" onClick={handleResetManageColumn}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default Support


// [
//     {
//       $match: {
//         created_at: {
//           $lte: new Date("2024-01-06"),
//           $gte: new Date("2023-11-15"),
//         },
//       },
//     },
//     {
//     $group: {
//       _id: {
//         date: {
//           $dateToString: {
//             format: "%Y-%d-%m", // Custom date format
//             date: "$created_at"
//           }
//         }
//       },
//       count: {
//         $sum: 1,
//       },
//     },
//   }
//   ,
//     {
//       $sort:
//         /**
//          * Provide any number of field/order pairs.
//          */
//         {
//           "_id.day": -1,
//         },
//     },
//   ]