/* eslint-disable max-len */
export default {
  // baseUrl: "http://localhost:8000/api/v1/",
  // webUrl:"http://localhost:8000/web/v1/",;
  baseUrl: 'https://admin.addonstudy.com/api/v1/',
  webUrl:"https://admin.addonstudy.com/web/v1/",
  clientUrl:"https://www.addonstudy.com/",
  imageUrl:"https://crazyforstudy.s3.ap-south-1.amazonaws.com/uploads/",
  googleMapsApiKey: "AIzaSyD4WSUmy8f1tDcbik6CrjF4QSwLi5Cx2vw",
};
