import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { salesSearchAPI, saveSalesDetails } from 'src/api/SalesApi'

const SalesDataTableComponent = () => {

  const [salesTableData, setSalesTableData] = useState([])
  const [isSubmitting, setIsSubmitting] = useState('')
  const [isSuccessModalOpen, setIsSuccessModal] = useState(false)

  const columns = [
    {
      name: 'Name',
      sortable: true,
      cell: (row) => (<>{row?.userDetails?.[0]?.F_Name} {row?.userDetails?.[0]?.L_Name}</>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Contact',
      sortable: true,
      cell: (row) => (<>{row?.userDetails?.[0]?.Contact}</>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Email',
      sortable: true,
      cell: (row) => (<>{row?.userDetails?.[0]?.Email}</>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Date of Payment',
      sortable: true,
      cell: (row) => (<><input type="date" value={row?.dateOfPayment} name="dateOfPayment" onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)} 
      className={isSubmitting === row?._id && !row?.dateOfPayment ? 'error-input' : ''}
      /></>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Amount',
      sortable: true,
      cell: (row) => (<><input type="number" value={row?.amount} name="amount" onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)} 
      className={isSubmitting === row?._id && !row?.amount ? 'error-input' : ''}
      /></>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Service',
      sortable: true,
      cell: (row) => (<><input type="text" value={row?.service} name="service" onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)} 
      className={isSubmitting === row?._id && !row?.service ? 'error-input' : ''}
      /></>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Assignee',
      sortable: true,
      cell: (row) => (<><input type="text" value={row?.assignee} name="assignee" onChange={(e) => handleRowDataChange(row, e.target.name, e.target.value)} 
      className={isSubmitting === row?._id && !row?.assignee ? 'error-input' : ''}
      /></>),
      minWidth: '200px',
      center: true
    },
    {
      name: 'Status',
      cell: (row) => (
        <button className='FilterBtn' onClick={() => handleSalesEntrySubmission(row)}>Save</button>
      ),
      sortable: true,
      minWidth: '200px',
      center: true
    }
  ]

  useEffect(() => {
    getSalesTableData()
  }, [])

  const getSalesTableData = async () => {
    const res = await salesSearchAPI()
    setSalesTableData(res?.data?.data)
  }

  const handleSalesEntrySubmission = async(rowData) => {
    if(!rowData?.dateOfPayment || !rowData?.amount || !rowData?.service || !rowData?.assignee){
      setIsSubmitting(rowData?._id)
    }else{
     const res = await saveSalesDetails(rowData)
     if(res !== undefined){
      setIsSuccessModal(true)
     }
    }
  }

  const handleRowDataChange = (rowData, eventName, eventValue) => {
    const rowIdx = salesTableData.findIndex(row => row._id === rowData._id)
    setSalesTableData((prev) => {
      const arr = [...prev];
      arr[rowIdx][eventName] = eventValue
      return arr;
    });
  }


  return (
    <React.Fragment>
      <DataTable
        title=""
        columns={columns}
        data={salesTableData}
        className="tableH-color tableb"
        pagination
        highlightOnHover
        fixedHeader
      />
      <Modal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModal(false)}>
        <h2 style={{ textAlign: 'center' }}>Your entry has been successfully updated.</h2>
      </Modal>
    </React.Fragment>
  )
}

export default SalesDataTableComponent

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content modal-lg viewdtal p30">
        <span className="close-btn" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};