import React, { useEffect, useState } from 'react'
import Pagination from 'src/components/Pagination'
import { Link } from 'react-router-dom'
import './ChartComponent.css'; // Import your CSS file for styling

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { totalData } from 'src/api/EFinderApi';
import Navigation from './Navigation';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
};

// Get today's date
let today = new Date();
// Calculate the date 10 days ago
let dateTenDaysAgo = new Date(today);
dateTenDaysAgo.setDate(today.getDate() - 10);
const startdate = dateTenDaysAgo.toISOString().split('T')[0]
let dateTenDaysAgo2 = new Date(today);
dateTenDaysAgo2.setDate(today.getDate());
const enddate = dateTenDaysAgo2.toISOString().split('T')[0]

function getDateArray(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
        dateArray.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray.sort();
}

// function date_list(date){
//     // Create an array to store the last 10 dates
//     let last10Dates = [];
//     let start_date = new Date(date);
//     // Loop to get the last 10 dates
//     for (let i = 0; i <= 10; i++) {
//         // Calculate the date by subtracting days
//         let previousDate = new Date(start_date);
//         previousDate.setDate(start_date.getDate() - i);
//         // Add the date to the array
//         last10Dates.push(previousDate.toISOString().split('T')[0]);
//     }
//     return last10Dates.sort()
// }

function getTotalByDate(date, data) {
    const result = data.find(item => item._id === date);
    return result ? result.total : 0;
}


function sumArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        throw new Error('Arrays must have the same length');
    }

    let sumArray = [];
    for (let i = 0; i < arr1.length; i++) {
        sumArray.push(arr1[i] + arr2[i]);
    }
    return sumArray;
}

const Graph = () => {
    const [loading, setLoading] = useState(true)
    const [start_date, setStart_date] = useState(startdate)
    const [end_date, setEnd_date] = useState(enddate)
    const [start_date_2, setStart_date_2] = useState(start_date)
    const [end_date_2, setEnd_date_2] = useState(end_date)
    const [createdAt, setCreatedAt] = useState([])
    const [modifiedAt, setModifiedAt] = useState([])
    const [emailNotFoundmodifiedAt, setEmailNotFoundmodifiedAt] = useState([])
    const [modifiedAt_pattern, setModifiedAt_pattern] = useState([])
    const [compan_IVquery, setCompan_IVquery] = useState([])
    const [apollo, setApollo] = useState([])
    const [graphDtaes, setGraphDtaes] = useState([])
    useEffect(() => {
        getSearchUrlList(start_date, end_date)
        document.title = "Graph | Email Finder"; // Set the document title

        // setStart_date("2024-06-16")
        // setEnd_date("2024-06-18")
    }, []);

    function graph_data(data, start_date2, end_date2) {
        const data_graph = []
        // const data_date = data.map(item => item._id)

        let counter = 0
        // Loop to get the last 10 dates
        // for (let i = 10; i >= 0; i--) {
        //     // Calculate the date by subtracting days
        //     let previousDate = new Date(today);
        //     previousDate.setDate(today.getDate() - i);
        //     data_graph.push(getTotalByDate(previousDate.toISOString().split('T')[0], data))
        // }
        getDateArray(start_date2, end_date2).map(v => {
            // console.log("sds", v);
            data_graph.push(getTotalByDate(v, data))
        })
        return data_graph
    }
    const getSearchUrlList = async (start_date2, end_date2) => {
        let date = new Date(end_date2);
        date.setDate(date.getDate() + 1);
        let new_end_Date = date.toISOString().split('T')[0];
        const res = await totalData({ "startDate": start_date2, "endDate": new_end_Date })
        setGraphDtaes(getDateArray(start_date2, end_date2))
        setCreatedAt(graph_data(res.data.createdAt, start_date2, end_date2))
        setModifiedAt(graph_data(res.data.modifiedAtstart_date2, end_date2))
        setModifiedAt_pattern(graph_data(res.data.modifiedAt_pattern, start_date2, end_date2))
        setEmailNotFoundmodifiedAt(graph_data(res.data.emailNotFoundmodifiedAt, start_date2, end_date2))
        setCompan_IVquery(graph_data(res.data.compan_IVquery, start_date2, end_date2))
        setLoading(false)
        // const resultArray = graph_data(res.data.modifiedAt).map((element, index) => element - graph_data(res.data.modifiedAt_pattern)[index]);
        const resultArray = graph_data(res.data.modifiedAt, start_date2, end_date2).map((element, index) => {
            const difference = element - graph_data(res.data.modifiedAt_pattern, start_date2, end_date2)[index];
            return difference < 0 ? 0 : difference;
        });
        setApollo(resultArray)
    }

    const data = {
        labels: graphDtaes,
        datasets: [
            {
                label: 'Total Users',
                data: createdAt,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            // {
            //     label: 'Total @mail',
            //     data: modifiedAt,
            //     borderColor: 'rgb(204, 102, 255)',
            //     backgroundColor: 'rgba(204, 102, 255, 0.5)',
            // },
            {
                label: 'Allocated',
                data: sumArrays(modifiedAt_pattern, emailNotFoundmodifiedAt),
                borderColor: 'rgb(27 64 247)',
                backgroundColor: 'rgb(27 64 247 / 50%)',
            },
            {
                label: 'Gmail',
                data: modifiedAt_pattern,
                borderColor: 'rgb(255, 153, 51)',
                backgroundColor: 'rgba(255, 153, 51, 0.5)',
            },
            {
                label: 'Comapny',
                data: compan_IVquery,
                borderColor: 'rgb(51, 204, 102)',
                backgroundColor: 'rgba(51, 204, 102, 0.5)',
            },
            // {
            //     label: 'Apollo',
            //     data: apollo,
            //     borderColor: 'rgb(53, 5, 235)',
            //     backgroundColor: 'rgba(53, 5, 235, 0.5)',
            // },
        ],
    };
    const handleStartDateChange = (e) => {
        setStart_date_2(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEnd_date_2(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Start Date:', start_date_2);
            console.log('End Date:', end_date_2);
            // setStart_date(start_date_2)
            // setEnd_date(end_date_2)
            // console.log('getDateArray:', getDateArray(start_date, end_date));

            getSearchUrlList(start_date_2, end_date_2)
            // Optionally, reset the form fields after successful submission
            // Create a link to download the file
        } catch (error) {
            alert(error.response.data.message)
            console.error('Error submitting data:', error);
        }
    };

    return (
        <>
            {(loading === false) ?
                <>
                    <Navigation />
                    <div className="container">

                        <div className='card px-4 py-2 text-center flex-row gap-3 mt-3'>
                            <div className="d-flex gap-2 align-items-center my-1">
                                <span className="dtstart">Start:</span>
                                <input className="form-select" type="date" name="startDate" value={start_date_2} onChange={handleStartDateChange} />
                            </div>
                            <div className="d-flex gap-2 align-items-center my-1">
                                <span className="dtstart">End:</span>
                                <input className="form-select" type='date' name="endDate" value={end_date_2} onChange={handleEndDateChange} />
                            </div>
                            <button className="btn fw-normal mt-2 text-white" onClick={(e) => handleSubmit(e)}>Search</button>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-4" style={{ height: "370px", textAlign: "center" }}>
                                <Line options={options} data={data} />
                            </div>
                        </div>
                    </div>
                </>
                : ''
            }
        </>
    )
}

export default Graph
