import React, { useEffect, useState } from 'react'
import { editRow, getMailTemplates, saveNewTemplate, updateMailTemplateId } from 'src/api/EFinderApi'
import Pagination from 'src/components/Pagination'
import { Link } from 'react-router-dom'
import './modal-pop.css'; // Import CSS file for modal styling
import Navigation from './Navigation';


const MailTemplates = () => {
    const [total, setTotal] = useState(null)
    const [pageNo, setPageNo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState(null)
    const [isAuth, setIsAuth] = useState(true)
    const [mailTemplates, setMailTemplates] = useState([])

    useEffect(() => {
        if (isAuth === true) {
            getSearchUrlList()
        }
    }, [pageNo, isAuth])

    useEffect(() => {
        document.title = "Mail template | Email Finder"; // Set the document title
    }, []);

    const getSearchUrlList = async () => {
        const res = await getMailTemplates({})
        setMailTemplates(res?.data?.data)
        setLoading(false)
    }

    const updateTemplateurl = async (e, url_id) => {
        const mailTemplateId = e.target.value
        const res = await updateMailTemplateId({ _id: url_id, mailTemplateId: mailTemplateId })
        console.log(`on change: ${url_id}==${mailTemplateId}`);
    }
    const formInput = {
        title: '',
        description: ''
    }
    const [formData, setFormData] = useState(formInput);

    const [editId, setEditId] = useState('')
    const [formData2, setFormData2] = useState({
        collection: 'mail-templates',
        _id: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEdit = async (item_id) => {
        console.log(`lof d d: ${item_id}`);
        // setFormData({ ...formData2, ['_id']: item_id });
        formData2['_id'] = item_id
        const response = await editRow(formData2);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        console.log(`data log:: ${response.data?.data.title}`);
        if (response.data?.data.title) {
            setEditId(item_id)
            setFormData({ title: response.data.data.title, description: response.data.data.description, _id: item_id });
        }
    };
    const handleReset = () => {
        // Reset form fields by setting the state to initial values
        setFormData(formInput);
        setEditId('')
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await saveNewTemplate(formData);
            console.log('Data submitted:', formData, response);
            // Optionally, reset the form fields after successful submission
            if (response.data.data === "exist") {
                alert('Already exist.')
            } else {
                setFormData({ title: '', description: '' });
                setEditId('')
                setLoading(true)
                getSearchUrlList()
            }
        } catch (error) {
            alert(error.response.data.message)
            console.error('Error submitting data:', error);
        }
    };
    const dynamicString = "{FirstName}";

    const handleCheckboxChange = async (e, item_id, status) => {
        e.preventDefault();
        const data = { 'status': e.target.checked, _id: item_id }
        // e.target.checked = !e.target.checked;
        const response = await saveNewTemplate(data);
        console.log(`loggg : ${e.target.checked} == ${item_id}===${status}`);

        const updatedItems = mailTemplates.map(item => {
            if (item._id === item_id) {
                // Toggle the status of the clicked item
                return { ...item, status: !e.target.checked };
            }
            return item;
        });
        setMailTemplates(updatedItems);
    }
    const [isOpen, setIsOpen] = useState(false);
    const [preview, setPreview] = useState({
        title: '',
        description: '',
    });

    const openModal = async (item_id) => {
        formData2['_id'] = item_id
        const response = await editRow(formData2);
        setPreview({ title: response.data.data.title, description: response.data.data.description });
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <React.Fragment>
            {
                isAuth === true ? (
                    <>
                        <Navigation />

                        {isOpen && (
                            <div className="modal mdnbvmm">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <span className="close" onClick={closeModal}>&times;</span>
                                        <h2>{preview.title}</h2>
                                        <hr />
                                        <div dangerouslySetInnerHTML={{ __html: preview.description }} />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="container mt-4">
                        <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Subject</label>
                                    <input type="text" className="form-control" id="title" name="title" value={formData.title} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Mail Body (dynamic var like {dynamicString})</label>
                                    <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} required>{formData.description}</textarea>
                                </div>
                                <input type='hidden' name='_id' value={editId} />
                                <button type="submit" className="btn editPrev">Save</button> &nbsp; &nbsp;
                                <button type='button' className="resetbtn" onClick={handleReset}>Reset</button>
                            </form>
                        </div>
                        <div className="col-12 grid-margin  my-4">
                            <div className="card">
                                <div className="card-body p-0 ps-0 pe-0">
                                    {
                                        loading === true ? <p>Loading your data....</p> : (
                                            <div>
                                                <div className="table-responsive newYautoscroll">
                                                    <table className="table table-center datatablefnts datatable">
                                                        <thead>
                                                            <tr>
                                                                <th>S. No   </th>
                                                                <th>Template-ID</th>
                                                                <th>Subject</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mailTemplates.map((item, idx) => {
                                                                return (
                                                                    <tr key={idx}>
                                                                        <td>{(pageNo) * 100 + idx + 1}</td>
                                                                        <td>{item._id}</td>
                                                                        <td>{item.title}</td>
                                                                        <td>
                                                                            <div className="form-check form-switch d-flex justify-content-center">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    checked={item.status || false} // Use state variable to control the checked state
                                                                                    onChange={(e) => handleCheckboxChange(e, item._id, item.status)} // Handle checkbox state change
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" className="btn editPrev" onClick={() => handleEdit(item._id)}>Edit</button>
                                                                            /
                                                                            <button type="button" onClick={() => openModal(item._id)} className="btn btn-sm editPrev">Preview</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {mailTemplates && mailTemplates?.length > 0 && <Pagination total={total} data={mailTemplates} setPageNo={setPageNo} pageNo={pageNo} />}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                       
                    </>
                ) :
                    ''
            }
        </React.Fragment>
    )
}

export default MailTemplates