import React, { useState } from 'react'
// import { TableContents } from 'src/containers/student-container/TabsConfiguration'
import { TableContents } from '../../containers/student-container/TabsConfiguration'
import { useEffect } from 'react'
// import { getDateFromTimestamp } from 'src/utils/CommonUtils'
import { getDateFromTimestamp } from '../../utils/CommonUtils'
// import Pagination from './Pagination'
import Pagination from '../Pagination'
import { useSelector } from 'react-redux'

const StudentContainerTabPane = ({ loading,activeSelectedTab, tableData, setFilter, handleFilterChange, pageNo, setPageNo, total,previousDayWebCount,previousDayAppCount }) => {
   const { search } = useSelector((state) => state.StudentReducer)


    

   return (
      <>
         <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id={`pills-${activeSelectedTab.id}`} role="tabpanel" aria-labelledby={`pills-tab${activeSelectedTab.id}`}>
               <div className="col-12 grid-margin  mt-4">
                  <div className="card">
                     <div className="card-body ps-0 pe-0">
                        {
                         tableData.length ===0 ? <p>Loading...</p> : 
                           (
                              <div className="table-responsive">
                           <table className="table table-center datatablefnts datatable">
                              <thead>
                                 <tr>
                                    <th>S. No   </th>
                                    {TableContents[activeSelectedTab.tabName].tableHeaders.map((heads) => {
                                       return (
                                          <th>{heads.label}</th>
                                       )
                                    })}
                                 </tr>
                              </thead>
                              <tbody>
                                 {tableData.map((data, idx) => {
                                    return (
                                       <tr key={idx}>
                                          <td>{idx + 1}</td>
                                          {TableContents[activeSelectedTab.tabName].tableHeaders.map((heads) => {
                                             return (
                                                <>
                                                   {
                                                      heads.label === 'Registration Date' && (
                                                         <td>{getDateFromTimestamp(data.created_at)
                                                         }</td>
                                                      )
                                                   }
                                                   {/* {
                                                      heads.label === 'User ID' && (
                                                         <td>{Math.floor(100000 + Math.random() * 900000)}</td>
                                                      )
                                                   } */}
                                                   {heads.value === 'fcm_token' ? (
                                                      <td>{data.fcm_token === null ? 'Web' : 'App'}</td>
                                                   ) : (
                                                      <td style={{ display: heads.label === 'Registration Date' && 'none' }}>{data[heads.value]}
                                                         {heads.value === 'Email' && (
                                                            <div className="messagebn1">
                                                               <a href={`mailto:${data.Email}`}><button><i className="mdi mdi-email-outline" target="_blank"></i> Message</button></a>
                                                            </div>
                                                         )}
                                                         {heads.value === 'Contact' && (
                                                            <>
                                                               <div className="callphnwhaaap">
                                                                  <a href={`https://api.whatsapp.com/send?phone=${data.Contact}`} title="Share on whatsapp" target="_blank">         <button className="phobnt1"><i className="mdi mdi-phone"></i> Call</button>
                                                                     <button className="whatsap1"><i className="mdi mdi-whatsapp"></i></button></a>
                                                               </div>
                                                            </>
                                                         )}

                                                         {
                                                            heads.label === 'Subscription Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.SubscribeDate)
                                                               }</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Cancel Reason' && (
                                                               <td>{data.transactions?.[0]?.reason}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Subscription Type' && (
                                                               <td>{data.transactions?.[0]?.plan}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Start Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.SubscribeDate)}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'End Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.SubscribeDate)}</td>
                                                            )
                                                         }
                                                         {
                                                            heads.label === 'Cancel Date' && (
                                                               <td>{getDateFromTimestamp(data.transactions?.[0]?.CancelledDate)}</td>
                                                            )
                                                         }
                                                      </td>
                                                   )}

                                                </>
                                             )
                                          })}
                                       </tr>

                                    )
                                 })}
                              </tbody>
                           </table>
                           {tableData && tableData?.length > 0 && <Pagination total={total} data={tableData} setPageNo={setPageNo} pageNo={pageNo} />}

                        </div>
                           )
                        }
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>
   )
}


export default StudentContainerTabPane