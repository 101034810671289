export const TabDetails = [
    {
        id:1,
        heading:'Registered',
        lineChart:'/assets/images/student-img/line-chart.svg',
        icon:'icon2'
    },
    {
        id:2,
        heading:'Subscribed',
        lineChart:'/assets/images/student-img/line-chart2.svg',
        icon:'icon7'
    },
    {
        id:3,
        heading:'Non-Subscribed',
        lineChart:'/assets/images/student-img/line-chart3.svg',
        icon:'icon5'
    },
    {
        id:4,
        heading:'Cancelled Subscription',
        lineChart:'/assets/images/student-img/line-chart4.svg',
        icon:'icon3'
    },
    {
        id:5,
        heading:'Student reviews',
        lineChart:'/assets/images/student-img/line-chart5.svg',
        icon:'icon1'
    }
]

export const TableContents = {
    Registered:{
tableHeaders:[
    {
        label:'User ID',
        value:''
    },
    {
        label:'User Name',
        value:'Name'
    },
    {
        label:'Email ID',
        value:'Email'
    },
    {
        label:'Phone Number',
        value:'Contact'
    },
    {
        label:'App or Web',
        value:'fcm_token'
    },
    {
        label:'Country',
        value:'Country'
    },
    {
        label:'Gender   ',
        value:'Gender'
    },
    {
        label:'Registration Date',
        value:'created_at'
    },
    {
        label:'Is Subscribe?',
        value:'Subscribe'
    }
]
    },
    Subscribed:{
     tableHeaders:[
        {
            label:'User ID',
            value:''
        },
        {
            label:'User Name',
            value:'Name'
        },
        {
            label:'Email ID',
            value:'Email'
        },
        {
            label:'Phone Number',
            value:'Contact'
        },
        {
            label:'App or Web',
            value:'fcm_token'
        },
        {
            label:'Country',
            value:'Country'
        },
        {
            label:'Gender   ',
            value:'Gender'
        },
        {
            label:'Registration Date',
            value:'created_at'
        },
        {
            label:'Subscription Date',
            value:'transactions?.[0]?.SubscribeDate'
        },
        {
            label:'Subscription Type',
            value:'transactions?.[0]?.plan'
        },
        {
            label:'Start Date',
            value:'transactions?.[0]?.SubscribeDate'
        },
        {
            label:'End Date',
            value:'transactions?.[0]?.SubscribeDate'
        },
        {
           label:'Amount',
           value:'amount'
        }
     ]
    },
    "Cancelled Subscription":{
        tableHeaders:[
            {
                label:'User ID',
                value:''
            },
            {
                label:'User Name',
                value:'Name'
            },
            {
                label:'Email ID',
                value:'Email'
            },
            {
                label:'Phone Number',
                value:'Contact'
            },
            {
                label:'App or Web',
                value:'fcm_token'
            },
            {
                label:'Country',
                value:'Country'
            },
            {
                label:'Gender   ',
                value:'Gender'
            },
            {
                label:'Registration Date',
                value:'created_at'
            },
           {
               label:'Subscription Date',
               value:'transactions?[0]?.SubscribeDate'
           },
           {
               label:'Subscription Type',
               value:'transactions?[0]?.plan'
           },
           {
               label:'Start Date',
               value:'transactions[0]SubscribeDate'
           },
           {
               label:'End Date',
               value:'transactions[0]SubscribeDate'
           },
           {
              label:'Amount',
              value:'amount'
           },
           {
            label:'Cancel Date',
            value:'transactions[0].cancelledDate'
           },
           {
            label:'Cancel Reason',
            value:'transactions[0].reason'
           }
        ]
       },
       'Non-Subscribed':{
        tableHeaders:[
            {
                label:'User ID',
                value:''
            },
            {
                label:'User Name',
                value:'Name'
            },
            {
                label:'Email ID',
                value:'Email'
            },
            {
                label:'Phone Number',
                value:'Contact'
            },
            {
                label:'App or Web',
                value:'fcm_token'
            },
            {
                label:'Country',
                value:'Country'
            },
            {
                label:'Gender   ',
                value:'Gender'
            },
            {
                label:'Registration Date',
                value:'created_at'
            }
        ]
       },
       'Student reviews':{
        tableHeaders:[
            {
                label:'User ID',
                value:''
            },
            {
                label:'User Name',
                value:'Name'
            },
            {
                label:'Subscription Status',
                value:'Subscribe'
            },
            {
                label:'Service Type',
                value:'Subscribe'
            },
            {
                label:'Rating ',
                value:'Subscribe'
            },
            {
                label:'Review Date',
                value:'Subscribe'
            },
            {
                label:' Status  ',
                value:'Subscribe'
            },
            {
                label:' Review Content ',
                value:'Subscribe'
            },
            {
                label:' Action',
                value:'Subscribe'
            }
        ]
       }
}