import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const DashboardNavbar = () => {

const navigate = useNavigate()

const handleLogout = () => {
    localStorage.removeItem('accessToken')
    window.location.href = '/login'
}

    return (
        <React.Fragment>
              <div className="TopNavN1">
                <div className="logo"><img src="/assets/images/logo.png" width={'180'} height={'36'} /></div>
                <div className="tabsListN1">
                    <ul>
                        <li><Link to="/operations"><img src="/assets/images/operations.svg" /> <div className="">Operations</div></Link></li>
                        <li><Link to="/supply"><img src="/assets/images/supply.svg" /> <div className="">Supply </div></Link></li>
                        <li><Link to="/qa"><img src="/assets/images/quality.svg" /> <div className="">QA  </div></Link></li>
                        <li><Link to="/revenue"><img src="/assets/images/revenue.svg" /> <div className="">Revenue   </div></Link></li>
                        <li><Link to="/finance"><img src="/assets/images/finance.svg" /> <div className="">Finance   </div></Link></li>
                        <li><Link to="/training"><img src="/assets/images/training.svg" /> <div className="">Training   </div></Link></li>
                        <li><Link to="/sales"><img src="/assets/images/sale.svg" /> <div className="">Sales   </div></Link></li>
                        <li><Link to="/marketing"><img src="/assets/images/marketing.svg" /> <div className="">Marketing   </div></Link></li>
                        <li><Link to="/support"><img src="/assets/images/support.svg" /> <div className="">Support   </div></Link></li>
                        <li><Link to="/admin"><img src="/assets/images/admin.svg" /> <div className="">Admin   </div></Link></li>
                        <li><Link to="/r-and-d"><img src="/assets/images/randd.svg" /> <div className="">R&D    </div></Link></li>
                        <li><Link to="/reviews"><img src="/assets/images/reviews.svg" /> <div className="">Reviews   </div></Link></li>
                        <li><Link to="/legal"><img src="/assets/images/legal.svg" /> <div className="">Legal</div></Link></li>
                    </ul>
                </div>
                <div className="logout"
                onClick={handleLogout}
                ><a href="javascript:void(0)"><img src="/assets/images/logout.svg" /></a></div> 
            </div> 
        </React.Fragment>
    )
}

export default DashboardNavbar