/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';


export const salesSearchAPI = function (data) {
    const url = `${config.webUrl}sales/get-sales-details`;
    return postRequest({
        url,
        data,
    });
};

export const saveSalesDetails = function(data) {
    const url = `${config.webUrl}sales/save-sales-details`;
    return postRequest({
        url,
        data
    })
}

// export const getStudentCounts = function(data){
//     const url = `${config.webUrl}contact/get-student-count-details`;
//     return postRequest({
//         url,
//         data
//     })
// }
