import { all } from 'redux-saga/effects'; 
// import AuthSaga from './containers/auth-container/saga/AuthSaga';
import AssignmentRequestSaga from './containers/assignment-request-container/saga/AssignmentRequestSaga';
import StudentSaga from './containers/student-container/saga/StudentSaga';

export default function* sagas() {
    yield all([
        ...AssignmentRequestSaga,
        ...StudentSaga
    ]);
}
