import { useState } from "react";
import { TIME_PERIOD } from "src/constants/CommonConstants";


const TimePeriodFilter=({handelFilter,skips,isTimePeriodFilterActive,setIsTimePeriodFilterActive})=>{
    const [currentActive , setCurrentActive] = useState("All")


     return(
            <div className="dailyTxtbg">
                <div className="dailyTxt1">
                    {
                        TIME_PERIOD.map((period,index)=>{
                            if(skips.includes(period.key)) return;

                            return (
                                <button 
                                    key={index}
                                    className={`btndaten1 ${(currentActive===period.key && isTimePeriodFilterActive)?'active':''}`}
                                    onClick={()=>{
                                        handelFilter({timePeriod:period.key})
                                        setCurrentActive(period.key)
                                    }}
                                >
                                    {period.value}
                                </button>
                                )
                        })
                    }
                </div>
            </div>
     )
}
export default TimePeriodFilter;