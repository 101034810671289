import React, { useEffect, useState } from 'react'
import { Badge } from 'antd';
import { useSocket } from 'src/socket-controller/SocketProvider';
import { addFiles, getAllResumeRequests } from 'src/api/ResumeRequestApi'
import {saveAssignmentChatMsgs, getAllAssignmentChats} from "src/api/AssignmentApi";


const ResumeExpertList = () => {

// const socket = useSocket('http://localhost:8000')
const socket = useSocket('https://admin.addonstudy.com')

const [tableData, setTableData] = useState([])
const [messagesArr, setMessagesArr] = useState([])
const [inputMessageValue, setInputMessageValue] = useState('')
const [fileSelected, setFileSelected] = useState(null)
const [orderIdClicked, setOrderIdClicked] = useState(null)
const [studentIdClicked, setStudentIdClicked] = useState(null)


useEffect(() => {
    if (socket) {
        // socket.on('messageIncoming', (data) => {
        //     // console.log('student message is',data)
        //     setMessagesArr((prev) => {
        //         if (prev !== undefined) {
        //             return [...prev, data]
        //         } else {
        //             return [data]
        //         }
        //     }
        //     )
        // });
        if (socket) {
            socket.emit('assignment_subscribe', {
                id: '743ehfjhebfuhe7hj',
                role: 'support',
                room_id:`${studentIdClicked}-${orderIdClicked}`
            })
        }
    }
}, [socket])

useEffect(() => {
    if(socket){
    socket.on('studentMessage', async(data) => {
        const response = await getAllAssignmentChats({room_id:`${studentIdClicked}-${orderIdClicked}`})
        setMessagesArr(response?.data?.data?.conversations)
        console.log('incoming msg is',response)
    });
}
}, [socket])

const handleMessageModalClick = async (userId, orderId) => {
    // console.log('chatArray', chatArray)
// setMessagesArr(chatArray)
// socket.emit('assignment_subscribe', {
//     id: '743ehfjhebfuhe7hj',
//     role: 'support',
//     room_id:"655b1e379299052326dc7e47-6457"
// })
const response = await getAllAssignmentChats({room_id:`${userId?._id}-${orderId}`})
// console.log('user chat is', response)
setStudentIdClicked(userId?._id)
setOrderIdClicked(orderId)
setMessagesArr(response?.data?.data?.conversations)
}

const handleMessageSubmit = async () => {
    await socket.emit('messageResponseExpert', {
        text: inputMessageValue.trim(),
        attachment: '',
        // userId: userId,
        room_id: `${studentIdClicked}-${orderIdClicked}`
    })
    await saveAssignmentChatMsgs({
        // student_id: studentId,
        // expert_id: userId,
        type: 'Expert',
        conversations: [{
            text: inputMessageValue.trim(),
            // userId: userId,
            // attachment: '',
            userRole: 'support',
            isSeen: false,
            createdAt: new Date()
        }],
        room_id: `${studentIdClicked}-${orderIdClicked}`
    }).then(() => {
setInputMessageValue('')
setMessagesArr((prev) => {
    if (prev !== undefined) {
        return [...prev, {

            text: inputMessageValue.trim(),
            attachment: '',
            userRole: 'support',
            isSeen: false,
            createdAt: new Date()
        }]
    } else {
        return [{
            text: inputMessageValue.trim(),
            // userId: userId,
            room_id: `${studentIdClicked}-${orderIdClicked}`,
            attachment: '',
            userRole: 'support',
            isSeen: false,
            createdAt: new Date()
            ,
            room_id: `${studentIdClicked}-${orderIdClicked}`
        }]
    }
})
    })
}

useEffect(() => {
getTableData()
}, [])    

const getTableData = async() => {
    const res = await getAllResumeRequests()
    setTableData(res?.data?.data)
    // console.log('table data is', res?.data?.data[0].user_id.created_at)
}


const handleUploadFile = async(e, itemId) => {
    if(e.target.files){
        const FileFormData = new FormData();
        FileFormData.append("file", e.target.files[0]);
        setFileSelected(e.target.files[0])
const upload_file = await addFiles(itemId, FileFormData)
console.log('upload_file', upload_file)
    }
}

    return (
        <React.Fragment>
               <table className="table table-center datatablefnts datatable">
                    <thead>
                        <tr>
                            <th>S. No   </th>
                            {/* <th>Assmt. ID </th> */}
                            <th>Order ID</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Contact</th>
                            <th>Experience Level</th>
                            <th>Plan Type</th>
                            <th>Amount</th>
                            <th>Upload</th>
                            <th>Chat</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            tableData.map((item, index) => {
                                // const remaningTime = formatDateDiff(assignment.deadline_date)
                                // const paymentStatus = (assignment.payment_status == "paid-full") ? "paid" : "Un Paid"
                                return (
                                    <tr key={index}>
                                        <td><span className="sNo">{index + 1}</span> </td>
                                        <td>{item?.order_id}    </td>
                                        <td>{item?.user_id?.F_Name}    </td>
                                        <td>{item?.user_id?.Email}   </td>
                                        {/* <td> */}
                                        {/* <div className="nambtntx"><a href="#">{assignment.user_id?.Name} </a></div> */}
                                        {/* </td> */}
                                        <td>
                                            {item?.user_id?.Contact}
                                        </td>
                                        <td>{item?.experienceLevel}</td>
                                        <td>{item?.planType}</td>
                                        <td>
                                           {item?.amount}
                                        </td>
                                        <td>
                                            {
                                                item?.attachment?.[0]?.file !== undefined ? <a href={`https://crazyforstudy.s3.ap-south-1.amazonaws.com/uploads/${item?.attachment?.[0]?.file}`} target='_blank'>{item?.attachment?.[0]?.file}</a> : 
                                                <input type = 'file'
                                                // value={item?.attachment?.[0]?.file}
                                                onChange={(e) => handleUploadFile(e, item?._id)}
                                                />
                                            }</td>
                                        <td 
                                        style={{cursor:'pointer'}}
                                        data-bs-toggle="modal" data-bs-target="#assignmentChatboxModal" data-bs-whatever="@mdo" 
                                        onClick={()  => handleMessageModalClick(item?.user_id, item?.order_id)}
                                        >
                                             <Badge count={0} offset={[-5, 10]}>
                                            {/* <Avatar shape="square" size="large" /> */}
                                            <i className="mdi mdi-bell" style={{ fontSize: '40px' }}></i>
                                        </Badge>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div class="modal fade chatboxModal" id="assignmentChatboxModal" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content modal-content bg-white chat_box">
                        <div class="modal-header head">

                            <div class="user">
                                <div class="avatar">
                                    <img src="https://picsum.photos/g/40/40" />
                                </div>
                                <div class="name">Hi there! You're talking to AddonStudy &#58; &#41;</div>
                            </div>
                            <button type="button" class="close font-26 text-white" data-bs-dismiss="modal" aria-label="Close"> <i className="mdi mdi-close"></i></button>

                        </div>
                        <div class="modal-body body">
                            {/* <div className="dysMonth">Today</div> */}
                            {
                                messagesArr?.map((item) => {
                                    return (
                                        <div class={item.userRole === 'student' ? 'incoming' : 'outgoing'}>
                                        <div class={`bubble ${item.userRole === 'support'&& 'lower'}`}>
                                            <p>{item.text}</p>
                                            <div className="msgtime" style={{marginLeft:'4rem'}}>10:25PM</div>
                                        </div>
                                        </div>
                                    )
                                })
                            }
                            {/* <div class="incoming">
                                <div class="bubble">
                                    <p>Hi there  What would you like us to assist you with?</p>
                                    <div className="msgtime">10:25PM</div>
                                </div>
                                <div class="bubble">
                                    <p>Hi there  What would you like us to assist you with?</p>
                                    <div className="msgtime">10:26PM</div>
                                </div>
                            </div>
                            <div class="outgoing">
                                <div class="bubble lower">
                                    <p>Hi there  What would you like us to assist you with?</p>
                                    <div className="msgtime">10:27PM</div>
                                </div>
                                <div class="bubble">
                                    <p>Well you should get your Dad a cologne. Here smell it. Oh wait! ...</p>
                                    <div className="msgtime">10:28PM</div>
                                </div>
                            </div> */}
                            {/* <div class="typing">
                                <div class="bubble">
                                    <div class="ellipsis dot_1"></div>
                                    <div class="ellipsis dot_2"></div>
                                    <div class="ellipsis dot_3"></div>
                                </div>
                            </div> */}
                        </div>
                        <div class="foot">
                            <input type="text" class="msg" placeholder="Type a message..."
                              value={inputMessageValue}
                              onChange={(e) => setInputMessageValue(e.target.value)}
                            // value={inputMessage}
                            // onChange={(e) => setInputMessage(e.target.value)}
                            onKeyUp={(e) => {
                                if(e.key === 'Enter'){
                                    handleMessageSubmit()
                                }
                            }}
                            />
                            <button type="submit"
                             onClick={() => handleMessageSubmit()}
                             ><i class="mdi mdi-send"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ResumeExpertList