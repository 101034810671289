/* eslint-disable import/no-dynamic-require */
const isLive = true
const config = require(`./${isLive?'production':'local'}`);//`./${process.env.REACT_APP_MODE || 'local'}`
export const isProduction = () => isLive;//process.env.REACT_APP_MODE === 'production';
export default config.default;


// db.createUser(
//     {
//       user: "addon_admin",
//       pwd: "dmn_W$39IE!",
//       roles: [ { role: "root", db: "admin" } ]
//     }
//   )
  