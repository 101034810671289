// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdnbvmm.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}


.mdnbvmm .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    display: flex; justify-content: center; align-items: center;
}

.editPrev{
    border: none;
    background: #0080FF;
    color: #fff;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 20px;
}  
.form-switch .form-check-input {
    width: 4em; cursor: pointer;
    height: 2em;}
.resetbtn{border: none;
    background: #fff;
    color: #1a1a1a;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 20px;}
    .mdnbvmm .close:hover,
    .mdnbvmm .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/containers/efinder-container/modal-pop.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,UAAU;IACV,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,aAAa,EAAE,uBAAuB,EAAE,mBAAmB;AAC/D;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,UAAU,EAAE,eAAe;IAC3B,WAAW,CAAC;AAChB,UAAU,YAAY;IAClB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB,CAAC;IAClB;;IAEA,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".mdnbvmm.modal {\r\n    display: block;\r\n    position: fixed;\r\n    z-index: 1;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n\r\n.mdnbvmm .close {\r\n    color: #aaa;\r\n    float: right;\r\n    font-size: 28px;\r\n    font-weight: bold;\r\n    cursor: pointer;\r\n    display: flex; justify-content: center; align-items: center;\r\n}\r\n\r\n.editPrev{\r\n    border: none;\r\n    background: #0080FF;\r\n    color: #fff;\r\n    border-radius: 7px;\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    padding: 4px 20px;\r\n}  \r\n.form-switch .form-check-input {\r\n    width: 4em; cursor: pointer;\r\n    height: 2em;}\r\n.resetbtn{border: none;\r\n    background: #fff;\r\n    color: #1a1a1a;\r\n    border-radius: 7px;\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    padding: 4px 20px;}\r\n    .mdnbvmm .close:hover,\r\n    .mdnbvmm .close:focus {\r\n    color: black;\r\n    text-decoration: none;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
