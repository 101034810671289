/* eslint-disable max-len */
export default {
  baseUrl: 'http://localhost:8000/api/v1/',
  webUrl:"http://localhost:8000/web/v1/",
  clientUrl:"http://localhost:3000/",
  imageUrl:"https://crazyforstudy.s3.ap-south-1.amazonaws.com/uploads/",

// baseUrl: 'https://admin.crazyforstudy.com/api/v1/',
// webUrl:"https://admin.crazyforstudy.com/web/v1/",
  
  googleMapsApiKey: "AIzaSyD4WSUmy8f1tDcbik6CrjF4QSwLi5Cx2vw",
};
