/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { getRequest, postRequest } from 'src/utils/RequestUtil';


export const saveProspectDetails = function (data) {
    const url = `${config.webUrl}prospecting/save-prospecting-details`;
    return postRequest({
        url,
        data,   
    });
};

export const getProspectingDispositionDetails = function(data){
    const url = `${config.webUrl}prospecting/get-prospecting-disposition-details`;
    return getRequest({
        url,
        data,   
    });
}

export const getProspectingLeadStageDetails = function(data){
    const url = `${config.webUrl}prospecting/get-prospecting-lead-stage-details`;
    return getRequest({
        url,
        data,   
    });
}

export const getProspectingCounts = function(data){
    const url = `${config.webUrl}prospecting/get-prospecting-count`;
    return postRequest({
        url,
        data
    })
}

