import React, { useEffect, useState } from "react"
import DateRangeCalendar from "src/components/DateRangeCalendar"
import { TabDetails, TableContents } from "./TabsConfiguration"
import { useDispatch, useSelector } from "react-redux"
import { studentSearchAction } from "./actions/StudentActions"
import StudentContainerTabPane from "src/components/student/StudentContainerTabPane"
import { getStudentsCount } from "src/api/StudentApi"

const StudentContainer = () => {
   var start = new Date(0);
   start.setHours(0, 0, 0, 0);
   var end = new Date();
   end.setHours(23, 59, 59, 999);
   const dispatch = useDispatch()
   const [activeSelectedTab, setActiveSelectedTab] = useState({ tabName: 'Registered', id: 1 })
   const[pageNo,setPageNo] = useState(0);
   const [groupcounter, setGroupcounter] = useState({ 'Registered': 0, 'Subscribed': 0, 'Non-Subscribed': 0, 'Cancelled Subscription': 0, 'Student reviews': 0 })
   const [filter, setFilter] = useState({
      "created_at": {
         "$gt": start,
         "$lte": end
      }
   })
   const [dateFilter, setDateFilter] = useState({
      "created_at": {
         "$gt": start,
         "$lte": end
      }
   })
   const [previousDayAppCount, setPreviousDayAppCount] = useState(0)
   const [previousDayWebCount, setPreviousWebCount] = useState(0)
   const [tableData, setTableData] = useState([])
   const { search, searchReqStatus } = useSelector((state) => state.StudentReducer)
   const [loading, setLoading] = useState(false)
   const getStudents = async () => {
     await dispatch(studentSearchAction({
         where: filter,
         currentPage: pageNo,
         autopopulate: true
      }))
   }
 
   useEffect(() => {
      getAllGroupCounts()
   },[dateFilter])


   const getAllGroupCounts  = async() => {
      const res= await  getStudentsCount({where:dateFilter})
      setGroupcounter(res.data)
   }


   const getTabData = async() => {
      const {data} = search
      setPageNo(0)
      // setFilter({})
      if(activeSelectedTab.tabName  === 'Registered'){
         setTableData([])
         setLoading(true)
         handleFilterChange(dateFilter)
        await dispatch(studentSearchAction({
            where: dateFilter,
            currentPage: 0,
            autopopulate: true
         }))
         setLoading(false)
         // setTableData(data)
       }else if(activeSelectedTab.tabName === 'Subscribed'){
          const filteredArr = data.filter((item)=> item.Subscribe==='true')
         //  console.log('tab array is',data,filteredArr)
         //  setTableData(filteredArr)
         // handleFilterChange({Subscribe:'true'})
         setTableData([])
         setLoading(true)
         handleFilterChange({Subscribe:'true'})
        await dispatch(studentSearchAction({
            where: {...filter, Subscribe:'true'},
            currentPage: 0,
            autopopulate: true
         }))
         setLoading(false)
       }else if(activeSelectedTab.tabName === 'Cancelled Subscription'){
         //  const filteredArr = data.map((item) => item.transactions.filter((transac) => transac.subscription_status === 'active')).filter(subArr => subArr.length >0)
         const filteredArr = data.filter((item) => item.transactions?.[0]?.subscription_status === 'cancelled')
         //  setTableData(filteredArr)
       }else if(activeSelectedTab.tabName === 'Non-Subscribed'){
          const filteredArr = data.filter((item)=> item.Subscribe==='false')
         //  setTableData(filteredArr)
         setTableData([])
         setLoading(true)
         handleFilterChange({Subscribe:'false'})
        await dispatch(studentSearchAction({
            where: {...filter, Subscribe:'false'},
            currentPage:0,
            autopopulate: true
         }))
         setLoading(false)
       }
   }

   useEffect(()=>{
      getTabData()
   },[activeSelectedTab])


// useEffect(()=>{
//    getStudents()
// },[])

const handleDateRangefilter = (payload) => {
   setDateFilter(payload)
   setFilter({...filter,...payload})
}

   useEffect(() => {
      getStudents()
   }, [filter,pageNo,activeSelectedTab,dateFilter])

   useEffect(() => {
      const { groupCounter,data,lastHoursRegisteredStudentsWeb,lastHoursRegisteredStudentsApp } = search
      setTableData(data)
      // setGroupcounter(groupCounter)
      setPreviousWebCount(lastHoursRegisteredStudentsWeb)
      setPreviousDayAppCount(lastHoursRegisteredStudentsApp)
   }, [search])

   const handleFilterChange = (payload) => {
      setFilter({ ...filter, ...payload })
   }

   const handleActiveTabClick = (tabHeading,tabId) => {
      setActiveSelectedTab({ tabName: tabHeading, id: tabId })
   }

   return (
      <>
         <div className="content-wrapper">      
            <div className="page-header">
               <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white me-2" >
                     <i className="mdi mdi-home"></i>
                  </span> Student overview
               </h3>
               <nav aria-label="breadcrumb">
                  <ul className="breadcrumb">
                     <li className="breadcrumb-item active" aria-current="page">
                        <span></span>Admin <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                     </li>
                  </ul>
               </nav>
            </div>
            <div className="row">
               <div className="col-md-12">
                  <div className="card shadow">
                     <div className="card-body">
                        <DateRangeCalendar onSelect={handleDateRangefilter} />
                        <div className="tabsnrstl1 tabsnrstl-m5 styudnT1">
                           <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                              {
                                 TabDetails.map((tab, idx) => {
                                    return (
                                       <li className="nav-item" role="presentation" onClick={() => handleActiveTabClick(tab.heading,tab.id)}>
                                          <div className={`nav-link ${activeSelectedTab.tabName === tab.heading && 'active'}`} id={`pills-tab${idx + 1}`} data-bs-toggle="pill" data-bs-target={`#pills-${idx + 1}`} type="button" role="tab" aria-controls={`pills-${idx + 1}`} aria-selected="true">
                                             <div className="stretch-card asigntabnew1">
                                                <div className="card-img-holder">
                                                   <div className="card-body">
                                                      <div className="d-flex align-items-center">
                                                         <div className="me-3"><h4 className="font-weight-normal"><span className={`asigntabIcon ${tab.icon}`}>
                                                            <img src="/assets/images/student-overview/student-icons.svg" className="img-fluid img-icon" alt="" />
                                                            <i className="mdi mdi-plus"></i>
                                                         </span>
                                                         </h4></div>
                                                         <div className="">
                                                            <span className="valutext ms-0">{groupcounter[tab.heading]}</span>
                                                            <h2 className="mt-0">{tab.heading} </h2>
                                                            <h6 className="card-text">Students</h6>
                                                         </div>
                                                      </div>
                                                      <div className="pricdlrm1 d-flex justify-content-between align-items-center"><span>
                                                         <img src={tab.lineChart} className="img-fluid img1" alt="" />
                                                         <img src="/assets/images/student-img/line-chartw5.svg" className="img-fluid img2" alt="" />
                                                      </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </li>
                                    )
                                 })
                              }
                           </ul>
                        </div>
  <StudentContainerTabPane activeSelectedTab={activeSelectedTab} tableData={tableData} handleFilterChange={handleFilterChange}
  loading={loading}
 pageNo={pageNo}
 setPageNo={setPageNo}
 total={search.total}
 previousDayAppCount={previousDayAppCount}
 previousDayWebCount={previousDayWebCount}
 setFilter={setFilter}
  />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
      
      </>
   )
}

export default StudentContainer;