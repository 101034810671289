import React from 'react';

function formatNumber(num) {
  const numStr = num.toString();
  const lastThree = numStr.substring(numStr.length - 3);
  const otherNumbers = numStr.substring(0, numStr.length - 3);
  if (otherNumbers !== '') {
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThree;
  } else {
    return lastThree;
  }
}

const NumberComponent = (props) => {
  const { number } = props;

  // Call the formatNumber function with the number prop
  const formattedNumber = formatNumber(number);

  return formattedNumber;
};

export default NumberComponent;
