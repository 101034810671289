export const ASSIGNMENT_ACTIONS = {
    ASSIGNMENT_COUNT:'ASSIGNMENT_COUNT',

    ASSIGNMENT_SEARCH: 'ASSIGNMENT_SEARCH',
    ASSIGNMENT_SEARCH_SUCCESS: 'ASSIGNMENT_SEARCH_SUCCESS',
    ASSIGNMENT_SEARCH_ERROR: 'ASSIGNMENT_SEARCH_ERROR',

    ASSIGNMENT_UPSERT: 'ASSIGNMENT_UPSERT',
    ASSIGNMENT_UPSERT_SUCCESS: 'ASSIGNMENT_UPSERT_SUCCESS',
    ASSIGNMENT_UPSERT_ERROR: 'ASSIGNMENT_UPSERT_ERROR',

    ASSIGNMENT_EDIT: 'ASSIGNMENT_EDIT',

    ASSIGNMENT_RESET: 'ASSIGNMENT_RESET',
};

export const ASSIGNMENTCountAction = (payload) => ({
    type: ASSIGNMENT_ACTIONS.ASSIGNMENT_COUNT,
    ...payload
})

export const ASSIGNMENTResetAction = () => ({
    type: ASSIGNMENT_ACTIONS.ASSIGNMENT_RESET,
});

export const ASSIGNMENTSearchAction = (payload) => ({
    type: ASSIGNMENT_ACTIONS.ASSIGNMENT_SEARCH,
    ...payload,
});


export const ASSIGNMENTEditAction = (payload) => ({
    type: ASSIGNMENT_ACTIONS.ASSIGNMENT_EDIT,
    ...payload,
});

export const ASSIGNMENTUpsertAction = (payload) => ({
    type: ASSIGNMENT_ACTIONS.ASSIGNMENT_UPSERT,
    ...payload,
});

export default {
    ASSIGNMENT_ACTIONS,
    ASSIGNMENTSearchAction,
    ASSIGNMENTUpsertAction,
    ASSIGNMENTEditAction,
};
