/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
// import config from "../config";
import config from 'src/config/index';
// import { postRequest, putRequest } from "../utils/RequestUtils";
import { getRequest, postRequest,putRequest } from 'src/utils/RequestUtil';

export const getAllResumeRequests = function (data) {
    const url = `${config.webUrl}hire-resume-expert/get-resume-request-admin`;
    return getRequest({
        url,
        data,
    });
};

export async function addFiles(id,data) {
    const url = `${config.webUrl}hire-resume-expert/add-files-resume/${id}`;
    return putRequest({
        url,
        data,
    });
}
