/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, getRequest } from 'src/utils/RequestUtil';


export const getPaymentListFromStripe = function (data) {
    const url = `${config.webUrl}payment/list-all-payment-intents`;
    return getRequest({
        url,
        data,
    });
};

