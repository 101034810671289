import React, { useEffect, useState } from 'react'
import { SearchUrlList, editReferenceName, getMailTemplates, emailcsv, saveNewTemplate, saveURL, updateMailTemplateId } from 'src/api/EFinderApi'
import Pagination from 'src/components/Pagination'
import { Link } from 'react-router-dom'
import Navigation from './Navigation'
// import { toast } from 'node_modules/react-toastify/dist/index'
import { toast } from 'react-toastify'

const SearchUrl = () => {

    const [tableData, setTableData] = useState([])
    const [total, setTotal] = useState(null)
    const [dataCountWithEmail, setDataCountWithEmail] = useState(null)
    const [dataWithApolloStatus, setDataWithApolloStatus] = useState(null)
    const [uniqueCompanyCount, setUniqueCompanyCount] = useState(null)
    const [pageNo, setPageNo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState(null)
    const [isAuth, setIsAuth] = useState(true)
    const [error, setError] = useState('')
    const [editModeId, setEditModeId] = useState('')
    const [editModeCol, setEditModeCol] = useState('')
    const [inputvalue, setInputValue] = useState()
    const [mailTemplates, setMailTemplates] = useState([])
    const [pageLength] = useState(100)
    const [columnTotals, setColumnTotals] = useState({
        'expectedRecords': 0,
        'existRecordPrev': 0,
        'users': 0,
        'eTotal': 0,
        'apolloExecute': 0,
        'eApollo': 0,
        'eGmailExecute': 0,
        'eGmail': 0
    })
    useEffect(() => {
        document.title = "URL list | Email Finder"; // Set the document title
    }, []);

    useEffect(() => {
        if (isAuth === true) {
            getSearchUrlList()
        }
    }, [pageNo, isAuth])

    const getSearchUrlList = async () => {
        let expectedRecords = 0
        let existRecordPrev = 0
        let users = 0
        let eTotal = 0
        let apolloExecute = 0
        let eApollo = 0
        let eGmailExecute = 0
        let eGmail = 0
        const res = await SearchUrlList({ pageLength: pageLength, currentPage: pageNo })
        setTableData(res.data.data)
        setTotal(res.data.dataCount)
        res?.data?.data?.forEach((item) => {
            expectedRecords += parseInt(item.total_records);
        });
        res?.data?.data?.forEach((item) => {
            existRecordPrev += parseInt(item.exist_records_with_prev_url);
        });
        res?.data?.data?.forEach((item) => {
            users += parseInt(item.count_users);
        });
        res?.data?.data?.forEach((item) => {
            eTotal += parseInt(item.count_users_e_total);
        });
        res?.data?.data?.forEach((item) => {
            apolloExecute += parseInt(item.count_users_e_apollo_execute);
        });
        res?.data?.data?.forEach((item) => {
            eApollo += (parseInt(item.count_users_e_total) - parseInt(item.count_users_e_gmail));
        });
        res?.data?.data?.forEach((item) => {
            eGmailExecute += parseInt(item.count_users_e_gmail_execute);
        });
        res?.data?.data?.forEach((item) => {
            eGmail += parseInt(item.count_users_e_gmail);
        });
        // setDataCountWithEmail(res.data.dataCountWithEmail)
        // setDataWithApolloStatus(res.data.dataWithApolloStatus)
        // setUniqueCompanyCount(res.data.uniqueCompanyCount)
        setColumnTotals({
            'expectedRecords': expectedRecords,
            'existRecordPrev': existRecordPrev,
            'users': users,
            'eTotal': eTotal,
            'apolloExecute': apolloExecute,
            'eApollo': eApollo,
            'eGmailExecute': eGmailExecute,
            'eGmail': eGmail
        })
        const res2 = await getMailTemplates({})
        setMailTemplates(res2?.data?.data)
        setLoading(false)
    }
    const handlePasswordCheck = () => {
        if (password === 'password') {
            setIsAuth(true)
        } else {
            setIsAuth(false)
            setError('Incorrect password.')
        }
    }

    const handleInputEnter = (e) => {
        if (e.key === "Enter") {
            handlePasswordCheck()
        } else {
            return
        }
    }

    const handleAddInput = (rowId, typ) => {
        setEditModeId(rowId)
        setEditModeCol(typ)
    }

    const handleNameEdit = (inputValue, id, index, col_key) => {
        setInputValue(inputValue)
        const editData = tableData.map((item) =>
            item._id === id && col_key ? { ...item, [col_key]: inputValue } : item
        )
        setTableData(editData)
    }

    const saveName = async (rowId, idx, col1) => {
        const res = await editReferenceName({ _id: rowId, col: col1, val: inputvalue })
        const filteredData = tableData.indexOf((item) => item._id === rowId)
        setEditModeId('')
        setEditModeCol('')
    }
    const updateTemplateurl = async (e, url_id) => {
        const mailTemplateId = e.target.value
        const res = await updateMailTemplateId({ _id: url_id, mailTemplateId: mailTemplateId })
        console.log(`on change: ${url_id}==${mailTemplateId}`);
    }
    const formInput = {
        ReferenceName: '',
        urlDesignation: '',
        url: '',
        mailTemplateId: ''
    }
    const [formData, setFormData] = useState(formInput);
    const handleReset = () => {
        // Reset form fields by setting the state to initial values
        setFormData(formInput);
        // setEditId('')
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchData = async (page) => {
        console.log('qwqw qw', page);
        setCurrentPage(page);
        getSearchUrlList()
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]); // Fetch data when currentPage changes
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await saveURL(formData);
            console.log('Data submitted:', formData, response);
            // Optionally, reset the form fields after successful submission
            if (response.data.status === "Failure") {
                // alert('Already exist.')
                toast.error(response.data.data)
            } else {
                setFormData(formInput);
                // setEditId('')
                toast.success("Successfully added URL!")
                setLoading(true)
                getSearchUrlList()
            }
        } catch (error) {
            alert(error.response.data.message)
            console.error('Error submitting data:', error);
        }
    };

    const handleCsvSubmit = async (e, url_id, e_total) => {
        e.preventDefault();
        try {
            if (e_total === 0) {
                toast.error('Data not available.')
            } else {

                const response = await emailcsv({ url_id: url_id });
                console.log('Data submitted:', response);
                // Optionally, reset the form fields after successful submission
                // Create a link to download the file

                if (response.data.status === "Failure") {
                    toast.error(response.data.data)
                } else {
                    // const csv = parse(response.data.data);
                    // const url = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
                    const url = window.URL.createObjectURL(new Blob([response.data.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'download.csv');
                    document.body.appendChild(link);
                    link.click();
                }
            }
        } catch (error) {
            alert(error.response.data.message)
            console.error('Error submitting data:', error);
        }
    };

    return (
        <React.Fragment>
            {
                isAuth === true ? (
                    <>
                        <Navigation />
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <form onSubmit={handleSubmit}>
                                        <div className="d-flex wthdfn">
                                            <div className="mb-3 me-3 whth12">
                                                <label htmlFor="ReferenceName" className="form-label">Reference Name</label>
                                                <input type="text" className="form-control" id="ReferenceName" name="ReferenceName" value={formData.ReferenceName} onChange={handleChange} required />
                                            </div>
                                            <div className="mb-3 me-3 whth12">
                                                <label htmlFor="urlDesignation" className="form-label">Designation</label>
                                                <input type="text" className="form-control" id="urlDesignation" name="urlDesignation" value={formData.urlDesignation} onChange={handleChange} required />
                                            </div>
                                            <div className="mb-3 whth12">
                                                <label htmlFor="mailTemplateId" className="form-label">Mail Template</label>
                                                <select className="form-select" id='mailTemplateId' name='mailTemplateId' value={formData.mailTemplateId} onChange={handleChange} required>
                                                    <option value=''>--</option>
                                                    {
                                                        mailTemplates.map((mail, i) => {
                                                            return (
                                                                (mail.status) ?
                                                                    <option key={i} value={mail._id}>
                                                                        {mail.title}
                                                                    </option>
                                                                    : ''

                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="url" className="form-label">URL</label>
                                            <textarea className="form-control" id="url" name="url" value={formData.url} onChange={handleChange} required>{formData.description}</textarea>
                                        </div>

                                        <button type="submit" className="btn editPrev">Save</button> &nbsp; &nbsp;
                                        <button type='button' className="resetbtn" onClick={handleReset}>Reset</button>
                                    </form>
                                </div>
                                <div className="col-12 grid-margin  my-4">
                                    <div className="card">
                                        <div className="card-body p-0 ps-0 pe-0">
                                            {
                                                loading === true ? <p>Loading your data....</p> : (
                                                    <div>
                                                        <div className="table-responsive newYautoscroll">
                                                            <table className="table table-center datatablefnts datatable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S. No   </th>
                                                                        <th>URL-ID</th>
                                                                        <th>ReferenceName</th>
                                                                        <th>URL Designation</th>
                                                                        <th>MailTemplate</th>
                                                                        <th>ExpectedRecords</th>
                                                                        <th>ExistRecordsPrevUrl</th>
                                                                        <th>Users</th>
                                                                        <th>eTotal</th>
                                                                        <th>ApolloExecute</th>
                                                                        <th>eApollo</th>
                                                                        <th>eGmailExecute</th>
                                                                        <th>eGmail</th>
                                                                        <th>Created At</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {tableData.map((item, idx) => {
                                                                        return (
                                                                            <tr key={idx}>
                                                                                <td>{(pageNo) * 100 + idx + 1}</td>
                                                                                <td><Link target="_blank" to={item.url}>{item._id}</Link></td>
                                                                                <td onClick={() => handleAddInput(item?._id, 'ReferenceName')}>{editModeId === item?._id && editModeCol === 'ReferenceName' ? <>
                                                                                    <button className='FilterBtn ms-2 me-2' onClick={() => saveName(item?._id, idx, 'ReferenceName')}>Save</button>
                                                                                    <input
                                                                                        value={item?.ReferenceName}
                                                                                        onChange={(e) => handleNameEdit(e.target.value, item?._id, idx, 'ReferenceName')} /></> : item?.ReferenceName !== '' ? <div style={{ display: 'flex', justifyContent: 'start' }}>
                                                                                            <i className='mdi mdi-pencil' style={{ fontSize: '25px' }}></i>
                                                                                            <p>{item?.ReferenceName}</p>
                                                                                        </div> : <i className='mdi mdi-pencil' style={{ fontSize: '25px' }}></i>}</td>
                                                                                <td onClick={() => handleAddInput(item?._id, 'urlDesignation')}>{editModeId === item?._id && editModeCol === 'urlDesignation' ? <>
                                                                                    <button className='FilterBtn ms-2 me-2' onClick={() => saveName(item?._id, idx, 'urlDesignation')}>Save</button>
                                                                                    <input
                                                                                        value={item?.urlDesignation}
                                                                                        onChange={(e) => handleNameEdit(e.target.value, item?._id, idx, 'urlDesignation')} /></> : item?.urlDesignation !== '' ? <div style={{ display: 'flex', justifyContent: 'start' }}>
                                                                                            <i className='mdi mdi-pencil' style={{ fontSize: '25px' }}></i>
                                                                                            <p>{item?.urlDesignation}</p>
                                                                                        </div> : <i className='mdi mdi-pencil' style={{ fontSize: '25px' }}></i>}</td>
                                                                                <td>
                                                                                    <select style={{ maxWidth: '120px' }} name='mailTemplate' onChange={(e) => updateTemplateurl(e, item?._id)}>
                                                                                        <option value=''>--</option>
                                                                                        {
                                                                                            mailTemplates.map((mail, i) => {
                                                                                                return (
                                                                                                    (mail.status) ?
                                                                                                        <option key={i} selected={item?.mailTemplateId === mail._id} value={mail._id}>
                                                                                                            {mail.title}
                                                                                                        </option>
                                                                                                        : ''

                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td>{item.total_records}</td>
                                                                                <td>{item.exist_records_with_prev_url}</td>
                                                                                <td>{item.count_users}</td>
                                                                                <td>{item.count_users_e_total}</td>
                                                                                <td>{item.count_users_e_apollo_execute}</td>
                                                                                <td>{item.count_users_e_total - item.count_users_e_gmail}</td>
                                                                                <td>{item.count_users_e_gmail_execute}</td>
                                                                                <td>{item.count_users_e_gmail}</td>
                                                                                <td onClick={(e) => handleCsvSubmit(e, item?._id)}>{item.createdAt}</td>
                                                                                <td><button onClick={(e) => handleCsvSubmit(e, item?._id, item.count_users_e_total)} class="btn editPrev">DWNLD</button></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    <tr>
                                                                        <td>Totals</td>
                                                                        <td>--</td>
                                                                        <td>--</td>
                                                                        <td>--</td>
                                                                        <td>{columnTotals['expectedRecords']}</td>
                                                                        <td>{columnTotals['existRecordPrev']}</td>
                                                                        <td>{columnTotals['users']}</td>
                                                                        <td>{columnTotals['eTotal']}</td>
                                                                        <td>{columnTotals['apolloExecute']}</td>
                                                                        <td>{columnTotals['eApollo']}</td>
                                                                        <td>{columnTotals['eGmailExecute']}</td>
                                                                        <td>{columnTotals['eGmail']}</td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {tableData && tableData?.length > 0 && <Pagination total={total} data={tableData} setPageNo={setPageNo} pageNo={pageNo} pageLength={pageLength} />}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    (
                        <div className='card col-3 p-4 mt-5' style={{ margin: '0rem auto' }}>
                            <h2 className='text-center mb-3'>Enter your password</h2>
                            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={(e) => handleInputEnter(e)}
                            />
                            {error !== '' && <p className='mt-3 text-center text-danger'>{error}</p>}
                            <button className='btndaten1 active p-2 w-50 ' style={{ border: 'none', outline: 'none', color: '#fff', background: '#365BE2', margin: '1rem auto' }} onClick={handlePasswordCheck}>Submit</button>
                        </div>
                    )
            }
        </React.Fragment>
    )
}

export default SearchUrl